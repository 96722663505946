import styles from "./AddNewUser.module.css";
import styles2 from "../../../pages/RegistrationInitialPage.module.css";
import { PhoneInput } from "react-international-phone";
import { useEffect, useState } from "react";
import {
  errorToast,
  successToast,
} from "../../../components/Toast/ToastMessage";
import axios from "axios";
import "react-international-phone/style.css";
import { parsePhoneNumber } from "libphonenumber-js";
import { getAuthHeaders } from "../../../utils/GetHeaders";
import {
  REACT_APP_AUTH_SVC_API_BASE_URL,
  REACT_APP_ORG_API_BASE_URL,
} from "../../../utils/UrlConstants";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

interface IAddNewUser {
  handleClose: () => void;
}

const AddNewUser: React.FC<IAddNewUser> = ({ handleClose }) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [userFetched, setUserFetched] = useState<boolean>(false);
  const [organizationId, setOrganizationId] = useState<string>("");
  const [organizationName, setOrganizationName] = useState<string>("");
  const [orgDot, setOrgDot] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(true);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    fetchOrganization(organizationId);
  }, [organizationId]);

  const fetchUser = async () => {
    var url = `${REACT_APP_AUTH_SVC_API_BASE_URL}/me`;
    try {
      console.log(url);
      const response = await axios.get(url, {
        headers: getAuthHeaders(),
      });
      setOrganizationId(response.data.user.organizations[0]);
      setUserFetched(true);
    } catch (error) {
      console.error(error, error);
    }
  };

  const fetchOrganization = async (id: string) => {
    if (!id) {
      // If id is empty or falsy, fetchUser again to get updated organizationId
      await fetchUser();
      return;
    }

    const url = `${REACT_APP_ORG_API_BASE_URL}/organization/${id}`;
    try {
      const response = await axios.get(url);
      setOrganizationName(response.data.organization.name);
      setOrgDot(response.data.organization.dotNumber);
    } catch (error) {
      errorToast("Error fetching organization");
      console.error("Error fetching organization:", error);
    }
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFirstName = e.target.value;
    setFirstName(newFirstName);
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newLastName = e.target.value;
    setLastName(newLastName);
  };

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;

    setPassword(newPassword);
  };

  const handlePhoneNumber = (phone: string) => {
    const newPhoneNumber = phone;
    setPhone(newPhoneNumber);
  };

  const handleNextClick = () => {
    try {
      const phoneNumber = parsePhoneNumber(phone, "US");
      if (phoneNumber.isValid()) {
        setIsValid(true);
      } else {
        setIsValid(false);
        return;
      }
      if (phoneNumber.country != "US") {
        errorToast("Phone number should only for US");
        return;
      }
    } catch (error) {
      setIsValid(false);
      return;
    }

    const apiUrl = `${REACT_APP_AUTH_SVC_API_BASE_URL}/register`;

    const headers = {
      "Content-Type": "application/json",
    };

    const organizations = [{ dotNumber: orgDot, name: organizationName }];

    const data = {
      firstName: firstName,
      lastName: lastName,
      email: email.toLowerCase(),
      password: password,
      phone: phone,
      role: "renter",
      organizations: organizations,
    };

    axios
      .post(apiUrl, data, { headers })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else if (response.status === 400) {
          errorToast("Bad request: Please check your input data");
        } else {
          errorToast(`HTTP error ${response.status}`);
        }
      })
      .then((data) => {
        console.log(data);
        handleClose();
        successToast("Registration successful");
      })
      .catch((error) => {
        console.error(error);
        if (error.response) {
          errorToast(
            `HTTP error ${error.response.status}: ${error.response.data.message}`
          );
        } else if (error.request) {
          errorToast("Network error");
        } else {
          errorToast(`Error: ${error.message}`);
        }
      });
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles2.frameParent}>
          <div className={styles2.inputWithLabelParent}>
            <div
              className={styles2.inputWithLabel}
              onKeyDown={() => {}}
              role="button"
            >
              <div className={styles2.reefer}>First name&nbsp;</div>
              <div className={styles2.basicInput}>
                <input
                  type="text"
                  className={styles2.inputField}
                  value={firstName}
                  placeholder="First name"
                  style={{ border: "none", width: "90%" }}
                  onChange={handleFirstNameChange}
                />
              </div>
            </div>
            <div className={styles2.inputWithLabel1}>
              <div className={styles2.reefer}>Last name&nbsp;</div>
              <div className={styles2.basicInput}>
                <input
                  className={styles2.inputField}
                  value={lastName}
                  type="text"
                  placeholder="Last name"
                  style={{ border: "none", width: "90%", borderRadius: "10px" }}
                  onChange={handleLastNameChange}
                />
              </div>
            </div>
          </div>
          <div className={`${styles2.frameGroup} mt-2`}>
            <div className={styles2.frameContainer}>
              <div className={styles2.inputWithLabelWrapper}>
                <div className={styles2.inputWithLabel1}>
                  <div className={styles2.reefer}>Password</div>
                  <div className={styles2.basicInput}>
                    <input
                      className={styles2.inputText9}
                      type={showNewPassword ? "text" : "password"}
                      placeholder="Password"
                      value={password}
                      style={{ border: "none", borderRadius: "10px" }}
                      onChange={handlePassword}
                    />
                    <span
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      {showNewPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles2.inputWithLabelWrapper}>
                <div className={styles2.inputWithLabel1}>
                  <div className={styles2.reefer}>Email</div>
                  <div className={styles2.basicInput}>
                    <input
                      className={styles2.inputText9}
                      type="text"
                      placeholder="Email"
                      value={email}
                      style={{ border: "none", borderRadius: "10px" }}
                      onChange={handleEmail}
                    />
                  </div>
                </div>
              </div>
              <div className={styles2.inputWithLabelWrapper}>
                <div className={styles2.inputWithLabel1}>
                  <div className={styles2.reefer}>Phone number</div>

                  <PhoneInput
                    className={`${styles2.phoneNumberInput} ${styles2.inputWithLabel1} ${styles.phoneInput}`}
                    defaultCountry="US"
                    value={phone}
                    disableDialCodeAndPrefix={true}
                    placeholder="(123) 123-4567"
                    onChange={(phone) => {
                      handlePhoneNumber(phone);
                    }}
                    inputStyle={{
                      width: "80%",
                      fontSize: "16px",
                      color: "#64748b",
                      borderRadius: "8px",
                    }}
                  />
                  {!isValid && (
                    <p style={{ color: "red", marginLeft: "7%" }}>
                      Please add a valid US phone
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles2.button1} hoverButtons mt-5`}
        role="button"
        onClick={handleNextClick}
      >
        <div className={styles.text}>
          <b>Submit</b>
        </div>
      </div>
    </>
  );
};

export default AddNewUser;
