import { FunctionComponent, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Modal from "@mui/material/Modal";
import styles from "./css/UserAdministration.module.css";
import AdminSidebar from "../../pages/Admin/AdminSidebar";
import AdminNavBar from "./AdminNavBar";
import axios from "axios";
import DeleteForever from "@mui/icons-material/DeleteForever";
import CancelIcon from "@mui/icons-material/Cancel";
import { RegistrationData } from "@/src/store/types";
import { errorToast, successToast } from "../../components/Toast/ToastMessage";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import AddNewUser from "./addNewUser/AddNewUser";
import { getAuthHeaders } from "../../utils/GetHeaders";
import {
  REACT_APP_AUTH_SVC_API_BASE_URL,
  REACT_APP_ORG_API_BASE_URL,
} from "../../utils/UrlConstants";
import GenericModal from "../../components/Modal/GenericModal";

export interface Column {
  id: string;
  name: string;
}

export interface TableTypes {
  columns: Column[];
  rows: { [key: string]: string }[];
}

const UserAdministration: FunctionComponent = () => {
  const currentUser = useSelector((state: any) => state.currentUser);
  const [loading, setloading] = useState<boolean>(true);
  const moment = require("moment");
  const [rows, setRows] = useState<{ [key: string]: string }[]>([{}]);
  const [filteredRows, setFilteredRows] = useState<{ [key: string]: string }[]>(
    [{}]
  );

  const [filter, setFilter] = useState<string>("all");
  const [users, setUsers] = useState<any>([]);
  const [usersCount, setUsersCount] = useState<number>(0);
  const [page, pageChange] = useState<number>(0);
  const [rowPerPage, setRowPerPage] = useState<number>(5);
  const [userRole, setUserRole] = useState<string>("");
  const [isFetched, setIsFetched] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const columns: Column[] = [
    { id: "name", name: "NAME" },
    { id: "role", name: "ROLE" },
    { id: "email", name: "E-MAIL" },
    { id: "lastActive", name: "LAST ACTIVITY" },
    { id: "added", name: "ADDED" },
    { id: "delete", name: "" },
  ];

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  // We could need this in the future
  const fetchUser = async () => {
    var url = `${REACT_APP_AUTH_SVC_API_BASE_URL}/me`;
    try {
      console.log(url);
      const response = await axios.get(url, {
        headers: getAuthHeaders(),
      });

      setUserRole(response.data.user.role);

      setIsFetched(true);
    } catch (error) {
      console.error(error, error);
    }
  };

  useEffect(() => {
    if (currentUser.role !== "admin") {
      fetchUsers(false, currentUser.organizations[0]);
    } else {
      fetchUsers(true);
    }

    fetchUser();
  }, []);

  const handleChangePage = (event: any, newPage: any) => {
    pageChange(newPage);
  };

  const handleRowsPerPage = (event: any) => {
    setRowPerPage(event.target.value);
    pageChange(0);
  };

  const handleDeleteUser = async (id: string) => {
    if (userRole != "admin") {
      errorToast("You don't have permission to delete user");
    } else {
      const isConfirmed = confirm("Are you sure you want to delete this user?");
      if (isConfirmed) {
        await deleteUser(id);
        if (currentUser.role !== "admin") {
          fetchUsers(false, currentUser.organization[0]);
        } else {
          fetchUsers(true);
        }
      }
    }
  };

  const handleRoleSwitch = async (id: string) => {
    if (userRole != "admin") {
      errorToast("You don't have permission to change role");
    } else {
      const isConfirmed = confirm(
        "Are you sure you want to  make this user owner?"
      );
      if (isConfirmed) {
        await updateUserAsOwner(id);
        if (currentUser.role !== "admin") {
          fetchUsers(false, currentUser.organization[0]);
        } else {
          fetchUsers(true);
        }
      }
    }
  };

  const deleteUser = async (id: string) => {
    var url = `${REACT_APP_AUTH_SVC_API_BASE_URL}/admin/user/${id}`;
    try {
      const response = await axios.delete(url, {
        headers: getAuthHeaders(),
      });
      successToast("User removed successfully");
    } catch (error) {
      errorToast("Error while removing user");
      console.error(error);
    }
  };

  const updateUserAsOwner = async (id: string) => {
    var url = `${REACT_APP_AUTH_SVC_API_BASE_URL}/admin/user/${id}`;
    try {
      const response = await axios.put(
        url,
        { role: "renter" },
        {
          headers: getAuthHeaders(),
        }
      );
      successToast("User role updated successfully");
    } catch (error) {
      errorToast("Error while updating role");
      console.error(error);
    }
  };

  const fetchUsers = async (isAdmin: boolean, org_id?: string) => {
    var url = isAdmin
      ? `${REACT_APP_AUTH_SVC_API_BASE_URL}/admin/users`
      : `${REACT_APP_ORG_API_BASE_URL}/organization/${org_id}/users`;

    try {
      const response = await axios.get(url, {
        headers: getAuthHeaders(),
      });

      let users = response.data.users;
      setUsersCount(users.length);
      users = users.reverse();
      console.log(response);

      const formattedUsers = users.map((users: RegistrationData) => ({
        organization: users.organizations[0],
        _id: users._id,
        isBlocked: users.isBlocked,
        name: users.firstName + " " + users.lastName,
        role: `${users.role}${users.role == "driver" && users.roleSwitchRequest
            ? " -- role switch requested"
            : ""
          }`,
        email: users.email,
        lastActive: users.lastActivity,
        added: users.createdAt,
      }));
      setRows(formattedUsers);
      setFilteredRows(formattedUsers);
      setloading(false);
    } catch (error) {
      setUsers([]);
      setloading(false);
      errorToast("Error fetching users");
      console.error("Error fetching users:", error);
    }
  };

  const filterUsers = (filter: string) => {
    pageChange(0);
    let filteredData: any;
    switch (filter) {
      case "all":
        setFilteredRows(rows);
        setFilter("all");
        break;
      case "admins":
        filteredData = rows.filter((row) => row.role === "admin");
        setFilter("admins");
        setFilteredRows(filteredData);
        break;
      case "renters":
        filteredData = rows.filter((row) => row.role === "renter");
        setFilter("renters");
        setFilteredRows(filteredData);
        break;
      default:
        setFilteredRows(rows);
        setFilter("all");
        break;
    }
  };

  return (
    <>
      <div className={styles.adminNavBar}>
        <AdminNavBar />
      </div>
      <div className="d-flex justify-content-between" style={{ width: "100%", height: "100vh" }}>
        <div className={styles.adminSideBar}>
          <AdminSidebar />
        </div>
        <div className={styles.userAdministration}>
          <div className={styles.textParent}>
            <div
              className={styles.text15}
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Link to="/admin">
                  <span>Settings / </span>
                </Link>
                <span className={styles.userAdministration1}>
                  User administration
                </span>
              </div>
              <div>
                <div
                  className={`${styles.button2} hoverButtons`}
                  style={{ fontSize: "18px" }}
                  onClick={() => {
                    handleOpen();
                  }}
                >
                  <div className={styles.text18}>
                    <PersonAddAltIcon /> Add new staff
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.numberCardParent}>
              <div className={`${styles.numberCard} ${styles.userNumberCard}`}>
                <div className={styles.creditsUsedParent}>
                  <div className={styles.creditsUsed}>Users in company</div>
                </div>
                <div className={styles.div}>{usersCount} users</div>
                <div className={styles.numberCardInner}>
                  <div className={styles.frameChild} />
                </div>
                <div className={styles.ofCreditsUsed}>
                  according your subscription
                </div>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.creditsUsedGroup}>
                  <div className={styles.creditsUsed1}>Pending users</div>
                  <img
                    className={styles.iconoutlinequestionMarkCir1}
                    alt=""
                    src="/admin/main/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.div1}>-</div>
                <div className={styles.frameWrapper}>
                  <div className={styles.frameItem} />
                </div>
                <div className={styles.ofCreditsUsed1}>82$ of credits used</div>
              </div>
              <div className={styles.frameGroup}>
                <div className={styles.creditsUsedContainer}>
                  <div className={styles.creditsUsed2}>-</div>
                  <img
                    className={styles.iconoutlinequestionMarkCir1}
                    alt=""
                    src="/admin/main/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.div2}>-</div>
                <div className={styles.frameContainer}>
                  <div className={styles.frameInner} />
                </div>
                <div className={styles.ofCreditsUsed2}>82$ of credits used</div>
              </div>
            </div>
            <div className={styles.tabExampleLightDesktopParent}>
              <div className={styles.tabExampleLightDesktop}>
                <div className={styles.tabExampleLightDesktop1}>
                  <div className={styles.tabs}>
                    <div
                      className={styles.desktopTab1}
                      style={
                        filter === "all"
                          ? {
                            borderBottom: "2px solid #fa6f32",
                            color: "#fa6f32",
                          }
                          : {}
                      }
                    >
                      <div
                        className={styles.tab2Wrapper}
                        onClick={() => {
                          filterUsers("all");
                        }}
                      >
                        <div className={styles.tab2}>All</div>
                      </div>
                      <div className={styles.desktopTabChild1} />
                    </div>
                    <div
                      className={styles.desktopTab1}
                      style={
                        filter === "admins"
                          ? {
                            borderBottom: "2px solid #fa6f32",
                            color: "#fa6f32",
                          }
                          : {}
                      }
                    >
                      <div
                        className={styles.tab2Wrapper}
                        onClick={() => {
                          filterUsers("admins");
                        }}
                      >
                        <div className={styles.tab2}>Admins</div>
                      </div>
                      <div className={styles.desktopTabItem} />
                    </div>
                    <div
                      className={styles.desktopTab1}
                      style={
                        filter === "renters"
                          ? {
                            borderBottom: "2px solid #fa6f32",
                            color: "#fa6f32",
                          }
                          : {}
                      }
                    >
                      <div
                        className={styles.tab3Wrapper}
                        onClick={() => {
                          filterUsers("renters");
                        }}
                      >
                        <div className={styles.tab3}>Renters</div>
                      </div>
                      <div className={styles.desktopTabInner} />
                    </div>
                    <div className={styles.desktopTab3}>
                      <div className={styles.tab4Wrapper}>
                        <div className={styles.tab4}>On hold</div>
                      </div>
                      <div className={styles.desktopTabChild1} />
                    </div>
                    <div className={styles.desktopTab4}>
                      <div className={styles.tab5Wrapper}>
                        <div className={styles.tab5}>Hidden</div>
                      </div>
                      <div className={styles.desktopTabChild2} />
                    </div>
                    <div className={styles.desktopTab5}>
                      <div className={styles.tab6Wrapper}>
                        <div className={styles.tab6}>In rent</div>
                      </div>
                      <div className={styles.desktopTabChild3} />
                    </div>
                    <div className={styles.desktopTab6}>
                      <div className={styles.tab7Wrapper}>
                        <div className={styles.tab7}>History</div>
                      </div>
                      <div className={styles.desktopTabChild4} />
                    </div>
                  </div>
                  <div className={styles.underLine} />
                </div>
              </div>
              <div className={`${styles.frameDiv} ${styles.userTable}`}>
                {loading ? (
                  <>
                    <Loader />
                  </>
                ) : (
                  <>
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer sx={{ minHeight: 450, maxHeight: 1000 }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              {columns.map((column, i) => (
                                <TableCell
                                  className={styles.tableHeaderCell}
                                  style={{
                                    backgroundColor: "#F8FAFC",
                                    fontWeight: "600",
                                    color: "gray",
                                    textAlign: "center",
                                    width: "20%",
                                  }}
                                  key={i}
                                >
                                  {column.name}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredRows &&
                              filteredRows
                                .slice(
                                  page * rowPerPage,
                                  page * rowPerPage + rowPerPage
                                )
                                .map((row, i) => {
                                  return (
                                    <TableRow
                                      key={i}
                                      className={styles.tableRow}
                                      style={{
                                        backgroundColor: row.active
                                          ? "red"
                                          : "white",
                                      }}
                                    >
                                      {columns &&
                                        columns.map((column, i) => {
                                          let value = row[column.id];
                                          return (
                                            <TableCell
                                              key={i}
                                              style={{
                                                textAlign: "center",
                                                height: "2vh",
                                              }}
                                            >
                                              {column.id === "name" && (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "left",
                                                  }}
                                                  onClick={() =>
                                                    window.open(
                                                      `/#/organization/${row.organization}`,
                                                      "_blank"
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src="/admin/avatar2.png"
                                                    alt="Avatar"
                                                    style={{
                                                      marginRight: "5px",
                                                      width: "4vh",
                                                      height: "4vh",
                                                    }}
                                                  />
                                                  <div
                                                    style={{
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {row.name}
                                                    </span>
                                                    <br />
                                                    <span>{row.role}</span>
                                                  </div>
                                                </div>
                                              )}
                                              {column.id !== "name" &&
                                                column.id !== "lastActive" &&
                                                column.id !== "added" && (
                                                  <span
                                                    onClick={
                                                      column.id === "delete"
                                                        ? () =>
                                                          handleDeleteUser(
                                                            row._id
                                                          )
                                                        : currentUser.role ==
                                                          "admin" &&
                                                          column.id ===
                                                          "role" &&
                                                          value.includes(
                                                            " -- role switch requested"
                                                          )
                                                          ? () =>
                                                            handleRoleSwitch(
                                                              row._id
                                                            )
                                                          : undefined
                                                    }
                                                    style={{
                                                      borderRadius: "16px",
                                                      padding: "4%",
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      backgroundColor:
                                                        column.id == "role" &&
                                                          value &&
                                                          value.includes(
                                                            "employee"
                                                          )
                                                          ? "orange"
                                                          : column.id ==
                                                            "role" &&
                                                            value &&
                                                            value.includes(
                                                              "admin"
                                                            )
                                                            ? "#2bbdbd"
                                                            : column.id ==
                                                              "role" &&
                                                              value &&
                                                              value.includes(
                                                                "renter"
                                                              )
                                                              ? "#00BF74"
                                                              : column.id ==
                                                                "role" &&
                                                                value &&
                                                                value.includes(
                                                                  "driver"
                                                                )
                                                                ? "#FC9366"
                                                                : column.id ==
                                                                  "role" &&
                                                                  value &&
                                                                  value.includes(
                                                                    "owner"
                                                                  )
                                                                  ? "#8282e9"
                                                                  : "none",
                                                      color:
                                                        column.id === "delete"
                                                          ? "red"
                                                          : value === "employee"
                                                            ? "white"
                                                            : column.id ==
                                                              "role" &&
                                                              (value ===
                                                                "admin" ||
                                                                value.includes(
                                                                  "driver"
                                                                ) ||
                                                                value ===
                                                                "owner" ||
                                                                value ===
                                                                "renter")
                                                              ? "white"
                                                              : "inherit",
                                                    }}
                                                  >
                                                    {value}

                                                    {column.id === "delete" ? (
                                                      <DeleteForever />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                )}
                                              {column.id == "lastActive" && (
                                                <>
                                                  {moment(value).format(
                                                    "MMM D, YYYY"
                                                  )}
                                                </>
                                              )}
                                              {column.id == "added" && (
                                                <>
                                                  {moment(value).format(
                                                    "MMM D, YYYY"
                                                  )}
                                                </>
                                              )}
                                            </TableCell>
                                          );
                                        })}
                                    </TableRow>
                                  );
                                })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        className={styles.tablePagination}
                        sx={{ display: "flex", alignItems: "baseline" }}
                        rowsPerPageOptions={[5, 10, 25]}
                        page={page}
                        rowsPerPage={rowPerPage}
                        component="div"
                        count={filteredRows.length}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleRowsPerPage}
                      ></TablePagination>
                    </Paper>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <GenericModal
        cmp={AddNewUser}
        setShowModal={handleClose}
        showModal={open}
        onClickSubmit={handleClose}
        showFooter={false}
        title="Add User"
        cmpProps={{
          handleClose,
        }}
      />
    </>
  );
};

export default UserAdministration;

const style = {
  position: "absolute",
  overflow: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxHeight: "80vh",

  bgcolor: "#f8fafc",
  border: "1px solid #fff",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
  "& > form > div": {
    width: "unset",
  },
  "& > form": {
    backgroundColor: "unset",
  },
  "& > div > div": {
    left: "0",
    width: "unset",
    margin: "auto",
  },
  "@media (max-width: 750px)": {
    width: "90%",
    maxHeight: "90vh",
    padding: "2rem",
  },
  "@media (max-width: 500px)": {
    width: "100%",
    maxHeight: "100vh",
    padding: "1rem",
  },
};
