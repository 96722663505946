import React, { createContext, useState, ReactNode, useContext } from "react";

interface GlobalStateType {
  searchString: string;
  truckSearch: string;
  setTruckSearch: (s: string) => void;
  setSearchString: (s: string) => void;
  searchByLocation: string;
  setSearchByLocation: (l: string) => void;
  sideBarToggle: boolean;
  setSideBarToggle: (t: boolean) => void;
  openLogin: boolean;
  setOpenLogin: (t: boolean) => void;
  openForgot: boolean;
  setOpenForgot: (t: boolean) => void;
}

const GlobalContext = createContext<GlobalStateType | undefined>(undefined);

interface GlobalProviderProps {
  children: ReactNode;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [searchString, setSearchString] = useState<string>("");
  const [truckSearch, setTruckSearch] = useState<string>("");
  const [searchByLocation, setSearchByLocation] = useState<string>("");
  const [sideBarToggle, setSideBarToggle] = useState<boolean>(false);
  const [openLogin, setOpenLogin] = useState<boolean>(false);
  const [openForgot, setOpenForgot] = useState<boolean>(false);

  return (
    <GlobalContext.Provider
      value={{
        searchString,
        setSearchString,
        truckSearch,
        setTruckSearch,
        searchByLocation,
        setSearchByLocation,
        sideBarToggle,
        setSideBarToggle,
        openLogin,
        setOpenLogin,
        openForgot,
        setOpenForgot,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }
  return context;
};
