import React, { useState, useEffect } from "react";
import styles from "./Features.module.css";

interface FeatureItemProps {
  title: string;
  description: string;
  isActive: boolean;
}

const Features: React.FC = () => {
  const features = [
    {
      title: "Comprehensive Marketplace",
      description:
        "Our platform connects drivers, trucking companies, owner-operators, and rental services, simplifying the search for semi equipment leasing, renting, or purchasing.",
      isActive: true,
      image: "/landing/truckpark.png",
    },
    {
      title: "Maximize Idle Equipment",
      description:
        "Owner-operators can earn income by renting out idle trucks and trailers, benefiting both parties.",
      isActive: false,
      image: "/landing/handshake.png",
    },
    {
      title: "Lease to Purchase Programs",
      description:
        "We offer Lease to Purchase programs, allowing users to gradually own equipment with reduced upfront costs.",
      isActive: false,
      image: "/landing/onphone.png",
    },
    {
      title: "Flexible Options",
      description:
        "Our platform provides various leasing and rental plans, offering both short-term and long-term options to suit different needs and budgets.",
      isActive: false,
      image: "/landing/handshake_2.png",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % features.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [features.length]);

  return (
    <section className={styles.container2}>
      <header className={styles.header}>
        <div className={styles.eyebrowAndHeading}>
          {/* <p className={styles.eyebrow}>Why us?</p> */}
          <h1 className={styles.heading}>Why choose us?</h1>
        </div>
        <p className={styles.supportingText}>
          Our cutting-edge platform lets your business unlock the ability to
          rent and list quality trailers and trucks, tractors, and vans all in
          one place
        </p>
      </header>
      <div className={styles.featureList}>
        <div className={styles.featureListContainer}>
          {features.map((feature, index) => (
            <article key={index} className={styles.featureItem}>
              <div
                className={`${styles.featureBar} ${
                  index === activeIndex ? styles.featureBarActive : ""
                }`}
              />
              <div className={styles.featureContent}>
                <h3 className={styles.featureTitle}>{feature.title}</h3>
                <p className={styles.featureDescription}>
                  {feature.description}
                </p>
              </div>
            </article>
          ))}
        </div>
        <div className={styles.featureImageContainer}>
          <img
            src={features[activeIndex].image} // Dynamically change the image
            alt={`Image for ${features[activeIndex].title}`}
            className={styles.featureImage}
          />
        </div>
      </div>
    </section>
  );
};

export default Features;
