import { FunctionComponent, useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import styles from "./ProductPage.module.css";
import PhotosParent from "./PhotosParent";
import InfoContainer from "./InfoContainer";
import axios from "axios";
import ImageViewer from "./ImageViewer";
import Footer from "../Add_Truck/Footer";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  REACT_APP_TRUCK_API_BASE_URL,
  REACT_APP_ORG_API_BASE_URL,
} from "../../utils/UrlConstants";
import { useReactToPrint } from "react-to-print";
import { Tabs, Tab, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { errorToast } from "../../../src/components/Toast/ToastMessage";

type ImageUrl = string;

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100vh",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#fff",
}));

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#fff",
  overflowY: "auto",
  maxHeight: 'calc(88vh - 5px)',
}));

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? "gray" : "gray",
  borderRadius: 3,
  position: "sticky",
  marginBottom: "8px",
  left: "calc(50% - 15px)",
  zIndex: 5,
}));

interface Props {
  window?: () => Window;
}

const ProductPage: FunctionComponent = (props: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  const [imageViewer, setImageViewer] = useState<boolean>(false);
  const [images, setImages] = useState<ImageUrl[]>([]);
  const [ownerMail, setOwnerMail] = useState<string>("");
  const [ownerNumber, setOwnerNumber] = useState<string>("");
  const [truck, setTruck] = useState<any>({});
  const [imageCount, setImageCount] = useState<number>(0);
  const { id } = useParams();

  //Mobile Drawer
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const scrollToMap = () => {
    const mapDiv = document.getElementById("map");
    if (mapDiv) {
      mapDiv.scrollTo({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    fetchTruck();

    window.top?.scrollTo(0, 0);
  }, []);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const fetchTruck = async () => {
    var url = `${REACT_APP_TRUCK_API_BASE_URL}/trucks/${id}`;
    try {
      const response = await axios.get(url);
      console.log(response.data.truck[0]);
      console.log("response ^^");
      setTruck(response.data.truck[0]);
      setImageCount(
        Object.values(response.data.truck[0]?.images).filter(
          (key) => key !== ""
        ).length
      );
      const fetchedImages: ImageUrl[] = Object.values(
        response.data.truck[0].images
      );
      const updatedImages: ImageUrl[] = [...fetchedImages];
      console.log(fetchedImages);
      console.log("Updated Images");
      setImages(updatedImages);
      setOwnerMail(response.data.truck[0].userDetails.email);
      setOwnerNumber(response.data.truck[0].userDetails.phone);
      fetchOrganization(response.data.truck[0].organization);
    } catch (error) {
      console.error(error, error);
    }
  };

  const fetchOrganization = async (id: string) => {
    console.log("id vvvv");
    console.log(id);
    const url = `${REACT_APP_ORG_API_BASE_URL}/organization/${id}`;
    try {
      const response = await axios.get(url);
      console.log(response);
      if (response.status === 200) {
        console.log("Product page vvvv");
        console.log(response.data.organization);
      }
    } catch (error) {
      console.error("Error fetching organization:", error);
    }
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          text: "Check out this page!",
          url: window.location.href,
        });
        console.log("Page shared successfully!");
      } catch (error) {
        console.error("Error sharing the page", error);
      }
    } else {
      alert("Sharing is not supported on this device/browser.");
    }
  };

  const currentUser = useSelector((state: any) => state.currentUser);
  const [selectedHearts, setSelectedHearts] = useState<string[]>([]);
  const currentUserID = currentUser._id;

  useEffect(() => {
    const favorites =
      JSON.parse(localStorage.getItem("favorites") as string) || {};
    if (favorites[currentUserID]) {
      setSelectedHearts(favorites[currentUserID]);
    }
  }, [currentUserID]);

  const handleHeartClick = (index: any) => {
    if (currentUserID == "") return errorToast("Please login first");

    let updatedFavorites;
    if (selectedHearts.includes(index)) {
      updatedFavorites = selectedHearts.filter((item: any) => item !== index);
    } else {
      updatedFavorites = [...selectedHearts, index];
    }

    setSelectedHearts(updatedFavorites);

    const allFavorites =
      JSON.parse(localStorage.getItem("favorites") as string) || {};
    allFavorites[currentUserID] = updatedFavorites;
    localStorage.setItem("favorites", JSON.stringify(allFavorites));
  };

  return (
    <>
      {imageViewer ? (
        <ImageViewer images={images} setImageViewer={setImageViewer} />
      ) : (
        <>
          <div className={styles.productPage} ref={contentRef}>
            <div className={styles.tabExampleLightDesktopGroup}>
              <div className={styles.tabExampleLightDesktop1}>
                <Box className={styles.tabs1}>
                  <Tabs
                    sx={{
                      minHeight: "unset",
                      height: "min-content",
                      fontWeight: 600,
                      textTransform: "capitalize !important",
                      "& .MuiTab-root": {
                        // Default tab styles
                        color: "inherit",
                      },
                      "& .Mui-selected": {
                        // Apply orange color when tab is selected
                        color: "#fa6f32 !important",
                      },
                    }}
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    textColor="primary"
                    indicatorColor="primary"
                    className={styles.desktopTab7}
                  >
                    <Tab
                      label="Overview"
                      // href="#"
                      className={styles.tabItem}
                    />
                    <Tab label="Details" className={styles.tabItem} />
                    <Tab
                      label="Map"
                      onClick={scrollToMap}
                      className={styles.tabItem}
                    />
                  </Tabs>
                </Box>
                <div className={styles.underLine1} />
              </div>
              <div className={styles.buttonContainer}>
                <div className={styles.button7}>
                  <img
                    className={styles.iconoutlineprinter}
                    alt=""
                    src="/truck/iconoutlineprinter.svg"
                  />
                  <div
                    className={styles.text22}
                    onClick={() => reactToPrintFn()}
                  >
                    Print
                  </div>
                </div>
                <div className={styles.button8} onClick={handleShare}>
                  <img
                    className={styles.iconoutlineshare}
                    alt=""
                    src="/truck/iconoutlineshare.svg"
                  />
                  <div className={styles.text23}>Share</div>
                </div>
                <div
                  style={{ zIndex: "1", cursor: "pointer" }}
                  onClick={() => handleHeartClick(truck?._id)}
                  className={`${styles.button8} `}
                >
                  <img
                    onKeyDown={() => { }}
                    role="button"
                    alt=""
                    style={{
                      zIndex: "1",
                      position: "relative",
                      cursor: "pointer",
                    }}
                    src={
                      selectedHearts.includes(`${truck?._id}`)
                        ? "generics/redHeartIcon.svg"
                        : "generics/mdicardsheartoutline.svg"
                    }
                  />
                  <div className={styles.text24}>Save</div>
                </div>
              </div>
            </div>
            <div style={{ background: "#f8fafc" }}>
              <div className={styles.mainContainer}>
                <PhotosParent
                  setImageViewer={setImageViewer}
                  truck={truck}
                  imageCount={imageCount}
                  scrollToMap={scrollToMap}
                />
                <div className={styles.drawer}>
                  {isMobile ? (
                    <>
                      <Root>
                        <CssBaseline />
                        <Global
                          styles={{
                            ".MuiDrawer-root > .MuiPaper-root": {
                              height: `calc(80% - ${drawerBleeding}px)`,
                              overflow: "visible",
                            },
                          }}
                        />
                        <SwipeableDrawer
                          anchor="bottom"
                          open={open}
                          onClose={toggleDrawer(false)}
                          onOpen={toggleDrawer(true)}
                          swipeAreaWidth={drawerBleeding}
                          disableSwipeToOpen={false}
                          ModalProps={{
                            keepMounted: true,
                          }}
                        >
                          <StyledBox
                            sx={{
                              position: "absolute",
                              top: -drawerBleeding,
                              paddingTop: "8px",
                              boxShadow: "0px -14px 18px 0px rgba(0,0,0,0.3)",
                              borderTopLeftRadius: 16,
                              borderTopRightRadius: 16,
                              visibility: "visible",
                              right: 0,
                              left: 0,
                              paddingBottom: "10%",
                            }}
                          >
                            <Typography
                              sx={{
                                p: 2,
                                textAlign: "center",
                                color: "text.secondary",
                                position: "sticky",
                                top: 0,
                                backgroundColor: "white",
                                zIndex: 4,
                              }}
                            >
                              <Puller />
                              Contact Owner
                            </Typography>

                            <InfoContainer
                              truck={truck}
                              mail={ownerMail}
                              ownerNumber={ownerNumber}
                            />
                            <Skeleton variant="rectangular" height="100%" />
                          </StyledBox>
                        </SwipeableDrawer>
                      </Root>
                    </>
                  ) : (
                    <InfoContainer
                      truck={truck}
                      mail={ownerMail}
                      ownerNumber={ownerNumber}
                    />
                  )}
                </div>
              </div>

              <div className={styles.footer}>
                <div className={styles.container}>
                  <div className={styles.container1}>
                    <div className={styles.links}>
                      <div className={styles.footerssimpleLink}>
                        <div className={styles.text25}>Terms & Conditions</div>
                      </div>
                      <div className={styles.footerssimpleLink1}>
                        <div className={styles.text26}>Privacy Policy</div>
                      </div>
                      <div className={styles.footerssimpleLink2}>
                        <div className={styles.text27}>Help Center</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.footersocialLinks}>
                    <div className={styles.footerssocialLink}>
                      <img
                        className={styles.footerssocialIcon}
                        alt=""
                        src="/truck/footerssocial-icon.svg"
                      />
                    </div>
                    <div className={styles.footerssocialLink1}>
                      <img
                        className={styles.footerssocialIcon1}
                        alt=""
                        src="/truck/footerssocial-icon-1.svg"
                      />
                    </div>
                    <div className={styles.footerssocialLink2}>
                      <img
                        className={styles.footerssocialIcon2}
                        alt=""
                        src="/truck/footerssocial-icon-2.svg"
                      />
                    </div>
                    <div className={styles.footerssocialLink3}>
                      <img
                        className={styles.footerssocialIcon3}
                        alt=""
                        src="/truck/footerssocial-icon-3.svg"
                      />
                    </div>
                    <div className={styles.footerssocialLink4}>
                      <img
                        className={styles.footerssocialIcon4}
                        alt=""
                        src="/truck/footerssocial-icon-4.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.copyright}>
                    © 2023 Truck4, Inc. All rights reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default ProductPage;
