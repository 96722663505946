import React from "react";
import styles from "./OwnerSection.module.css";

interface TestimonialProps {
  quote: string;
  avatarSrc: string;
  name: string;
  title: string;
}

const Testimonial: React.FC<TestimonialProps> = ({
  quote,
  avatarSrc,
  name,
  title,
}) => (
  <aside className={styles.testimonial}>
    <div className={styles.topBorder} />
    <div className={styles.testimonialContent}>
      <blockquote className={styles.quote}>{quote}</blockquote>
      <footer className={styles.testimonialFooter}>
        <img src={avatarSrc} alt="" className={styles.avatarImage} />
        <div className={styles.attribution}>{`${name}, ${title}`}</div>
      </footer>
    </div>
  </aside>
);

const OwnerSection: React.FC = () => {
  return (
    <section className={styles.section}>
      <div className={styles.leadingContent}>
        <header className={styles.header}>
          <div className={styles.headingWrapper}>
            <p className={styles.eyebrow}>For equipment owners</p>
            <div className={styles.headingContainer}>
              <h1 className={styles.heading}>
                Boost Your Income by Renting Out Unused Vehicles
              </h1>
              <p className={styles.supportingText}>
                Start renting out your idle commercial vehicles to gain
                significant revenue. It's safe and easy with Truck4. You can
                earn up to $5,000 per month for each vehicle.
              </p>
            </div>
          </div>
          <button className={styles.ctaButton}>Learn how</button>
        </header>
      </div>
      <div className={styles.featureImageContainer}>
        <img
          src="/landing/driver2.png"
          alt="Commercial vehicle for rent"
          className={styles.heroImage}
        />
      </div>
    </section>
  );
};

export default OwnerSection;
