import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const createCustomIcon = (
  truckCount: number,
  styles: any,
  truck?: any,
  singleTruck?: any
) => {
  return L.divIcon({
    className: `${styles.customMarker} hoverButtons`,
    html: `<div style="background-color: white; border-radius: 16px; width: 75px; height: 35px; display: flex; justify-content: center; align-items: center; color: black; font-weight: bold; box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);">
    ${
      truckCount > 1
        ? `+${truckCount}`
        : singleTruck
        ? `$${singleTruck?.rentPerDay}`
        : `$${truck?.rentPerDay}`
    }
  </div>`,
    iconSize: [50, 50],
    iconAnchor: [25, 25],
    popupAnchor: [13, -30],
  });
};

interface Truck {
  _id: string;
  city: string;
  state: string;
  zipCode: string;
  rentPerDay: number;
  year: number;
  manufacturer: string;
  model: string;
  position?: { lat: number; lon: number };
  images: { threeQuarterView: string };
}

interface MapComponentProps {
  trucks?: Truck[];
  styles?: any;
  id?: any;
  width?: string;
  singleTruck?: any;
}

const MapComponent: React.FC<MapComponentProps> = ({
  trucks,
  styles,
  id,
  width,
  singleTruck,
}) => {
  const position: [number, number] = [41.85532, -87.803921];
  const [truckLocations, setTruckLocations] = useState<
    { locationKey: string; trucks: Truck[]; position: [number, number] }[]
  >([]);
  const [singleTruckPosition, setSingleTruckPosition] = useState<
    [number, number] | null
  >(null);

  const hoveredTruckId = useSelector(
    (state: any) => state?.mapActions?.hoveredTruckId
  );

  const markerRefs = useRef<Map<string, any>>(new Map());

  useEffect(() => {
    console.log(trucks);
    const groupTrucksByLocation = () => {
      const locationMap = new Map<string, Truck[]>();

      trucks?.forEach((truck) => {
        if (truck.position) {
          const { lat, lon } = truck.position;
          const locationKey = `${lat}-${lon}`;
          if (!locationMap.has(locationKey)) {
            locationMap.set(locationKey, []);
          }
          locationMap.get(locationKey)?.push(truck);
        }
      });

      const locations = Array.from(locationMap.entries()).map(
        ([locationKey, truckList]) => ({
          locationKey,
          trucks: truckList,
          position: [
            truckList[0].position?.lat,
            truckList[0].position?.lon,
          ] as [number, number],
        })
      );

      setTruckLocations(locations);
    };

    groupTrucksByLocation();
  }, [trucks]);

  useEffect(() => {
    if (singleTruck?.position) {
      setSingleTruckPosition([
        singleTruck?.position?.lat,
        singleTruck?.position?.lon,
      ]);
    }
  }, [singleTruck]);

  useEffect(() => {
    if (hoveredTruckId && markerRefs.current.has(hoveredTruckId)) {
      const marker = markerRefs.current.get(hoveredTruckId);
      const currentIcon = marker.getIcon();

      const updatedIcon = L.divIcon({
        ...currentIcon.options,
        className: `custom-marker ${styles.hoveredMarker}`,
        html: currentIcon.options.html,
      });

      marker.setIcon(updatedIcon);
    }
  }, [hoveredTruckId]);

  const popupTimeouts = useRef(new Map());

  const handleMouseOver = (e: any, truckId: string) => {
    if (popupTimeouts.current.has(truckId)) {
      clearTimeout(popupTimeouts.current.get(truckId));
    }

    e.target.openPopup();

    // const popupElement = e.target._popup._container;
    // popupElement.addEventListener("mouseout", (event: any) => {
    //   if (!popupElement.contains(event.relatedTarget)) {
    //     e.target.closePopup();
    //   }
    // });

    // Set the timer to automatically close the popup after 5 seconds
    // const timeout = setTimeout(() => {
    //   e.target.closePopup();
    // }, 5000);

    // Store the timeout so it can be cleared later if necessary
    // popupTimeouts.current.set(truckId, timeout);
  };

  const handleMouseOut = (e: any, truckId: string) => {
    // const popupElement = e.target._popup._container;
    // if (
    //   !popupElement.contains(e.relatedTarget) &&
    //   !popupElement.contains(e.target)
    // ) {
    //   e.target.closePopup();
    // }
  };
  return (
    <MapContainer
      center={position}
      zoom={9}
      style={{ height: "99vh", width: width }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {truckLocations.map(({ locationKey, trucks, position }) => (
        <Marker
          icon={createCustomIcon(trucks.length, styles, trucks[0])}
          key={locationKey}
          position={position}
          ref={(ref) => {
            if (ref) {
              trucks.forEach((truck) => markerRefs.current.set(truck._id, ref));
            }
          }}
          eventHandlers={{
            click: (e) => handleMouseOver(e, locationKey),
            // mouseout: (e) => handleMouseOut(e, locationKey),
            // click: () => console.log(`Clicked on location: ${locationKey}`),
          }}
        >
          <Popup
            className={`${styles.popupScrollable} ${
              trucks.length > 1 ? styles.popupScrollable2 : ""
            }`}
          >
            <div
              className={` ${trucks.length > 1 ? styles.popupStacked : ""}`}
              style={{
                // maxHeight: "100%",
                overflowY: "auto",
              }}
            >
              {trucks.map((truck) => (
                <div key={truck._id}>
                  <Carousel
                    className={`${styles.vecicleCard2} ${styles.vecicleCard2_popup}`}
                    interval={null}
                  >
                    {Object.entries(truck.images)
                      .filter(([_, value]) => value)
                      .map(([key, value]) => (
                        <Carousel.Item
                          className={styles.vecicleCard2CarouselItem}
                          style={{ minHeight: "21vh" }}
                          key={`${truck._id}-${key}`}
                          onClick={() =>
                            window.open(`#/truck/${truck._id}`, "_blank")
                          }
                        >
                          <img
                            src={value}
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              height: "21vh",
                              objectFit: "cover",
                            }}
                            alt={`Truck ${key}`}
                          />
                        </Carousel.Item>
                      ))}
                  </Carousel>
                  <div
                    style={{
                      textAlign: "left",
                      padding: "0 15px 10px 15px",
                      fontSize: "16px",
                      fontWeight: 600,
                      marginTop: "5px",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          textTransform: "capitalize",
                          cursor: "pointer",
                          color: trucks.length > 1 ? "dodgerblue" : "inherit",
                        }}
                        onClick={() =>
                          window.open("/#/truck/" + truck._id, "_blank")
                        }
                      >
                        {truck.year} {truck.manufacturer} {truck.model}
                      </div>
                      {/* <div>
                        <div className={styles.materialSymbolsstarParent}>
                          <img
                            className={styles.materialSymbolsstarIcon}
                            alt=""
                            src="/generics/black_star.svg"
                          />
                          <div className={styles.milesAway}>5.0 (11)</div>
                        </div>
                      </div> */}
                    </div>
                    <div>${truck.rentPerDay}/Day</div>
                  </div>
                </div>
              ))}
            </div>
          </Popup>
        </Marker>
      ))}
      {singleTruckPosition && (
        <Marker
          icon={createCustomIcon(1, styles, singleTruck)}
          position={singleTruckPosition}
        >
          <Popup>
            {singleTruck.city}, {singleTruck.state}, {singleTruck.zipCode}
            <img
              style={{ height: "50px", width: "50px" }}
              src={singleTruck?.images.threeQuarterView}
              alt={`Truck ${singleTruck?._id}`}
            />
          </Popup>
        </Marker>
      )}
    </MapContainer>
  );
};

export default MapComponent;
