import React from "react";
import styles from "./VehicleTypes.module.css";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import { useGlobalContext } from "../../components/Global/GlobalState";
import { useNavigate } from "react-router-dom";

interface VehicleCard {
  image: string;
  title: string;
  price: string;
}

const vehicleData: VehicleCard[] = [
  {
    image: "/landing/truck_first.jpg",
    title: "Truck",
    price: "Starting $100 / day",
  },
  {
    image: "/landing/trailer.jpg",
    title: "Trailer",
    price: "Starting $20 / day",
  },
  {
    image: "/landing/boxtruck.jpg",

    title: "Box Truck",
    price: "Starting $15 / day",
  },
];

const VehicleTypes: React.FC = () => {
  const { setTruckSearch } = useGlobalContext();
  const navigate = useNavigate();

  return (
    <section className={styles.vehicleTypes}>
      <h2 className={styles.sectionTitle}>Vehicle Types</h2>
      <p className={styles.sectionDescription}>
        Gain access to variety of specialized commercial vehicles available for
        rent nationwide.
      </p>
      <div className={styles.cardContainer}>
        {vehicleData.map((vehicle, index) => (
          <Card
            sx={{ maxWidth: 345, boxShadow: 0 }}
            className={styles.card}
            onClick={() => {
              setTruckSearch(vehicle.title);
              navigate("/main");
            }}
          >
            <CardActionArea
              style={{ borderRadius: "16px" }}
              className={styles.cardParent}
            >
              <CardMedia
                className={styles.cardImage}
                component="img"
                height="140"
                image={vehicle.image}
                alt={vehicle.title}
                style={{ borderRadius: "8px", objectFit: "contain" }}
              />
              <CardContent
                style={{ textAlign: "left" }}
                className={styles.cardContent}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{ fontWeight: "600", letterSpacing: "-1px" }}
                >
                  {vehicle.title}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {vehicle.price}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
        <div
          className={styles.showAllCard}
          onClick={() => {
            setTruckSearch("");
            navigate("/main");
          }}
        >
          <button className={styles.showAllButton}>
            Show all
            <img
              src="/images/arrow-right-icon.png"
              alt=""
              className={styles.arrowIcon}
            />
          </button>
        </div>
      </div>
    </section>
  );
};

export default VehicleTypes;
