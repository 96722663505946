import { useSelector } from "react-redux";
import { UPDATE_TRUCK_REGISTRATION_DATA } from "../actions/actionTypes";
import { TruckRegistrationData } from "../types";

const initialState: TruckRegistrationData = {
  plateNumber: "",
  plateState: "",
  VIN: "",
  vehicleType: "",
  vehicleSubtype: "",
  VN: "",
  manufacturer: "",
  model: "",
  engine: "",
  year: 0,
  mileage: 0,
  doorType: "",
  liftGateType: undefined,
  length: {
    feet: 0,
    inches: 0,
  },
  cargoSecurementTrack: undefined,
  trailerType: "flatbed",
  dockHeight: undefined,
  allowTravelOutOfState: false,
  GPS: false,
  transmissionType: undefined,
  chassis: undefined,
  description: "",
  registrationStartDate: "01-01-2025",
  registrationEndDate: "01-02-2025",
  insuranceStartDate: "01-01-2025",
  insuranceEndDate: "01-02-2025",
  inspectionDate: "01-01-2025",
  enableForRent: false,
  rentPerDay: 0,
  rentPrepaidMileage: 0,
  enableForLease: false,
  enableForLeaseForPurchase: false,
  LeaseForPurchase: {
    pricePerWeek: 0,
    numberOfWeeks: 0,
    description: "",
  },
  leasePerDay: 0,
  leasePrepaidMileage: 0,

  crewCab: false,
  ramp: false,
  iftaCompliant: false,

  deposit: 0,
  equipmentPrice: 0,
  insurancePrice: 0,
  taxes: 0,
  images: {
    threeQuarterView: "",
    front: "",
    passengerSide: "",
    back: "",
  },
  files: {
    vehicleRegistrationFile: "",
    vehicleInsuranceFile: "",
    technicalInspectionFile: "",
  },

  organization: {
    dotNumber: "",
    name: "",
  },
  rentPriceType: undefined,
  leasePriceType: undefined,
  leaseForPurchasePriceType: undefined,

  state: "",
  city: "",
  zipCode: "",

  _id: undefined,
  __v: undefined,
};
export const updateTruckAddRegistrationData = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case UPDATE_TRUCK_REGISTRATION_DATA:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};
export default updateTruckAddRegistrationData;
