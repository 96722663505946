import React, { useCallback, useState } from "react";
import styles from "../../css/Registration_Driver/AddOrganization.module.css";
import styled from "styled-components";
import { connect } from "react-redux";
import { RegistrationData, RootState } from "../../store/types";
import { updateRegistrationData } from "../../store/actions/registrationActions";

export type AddOrganizationProps = {
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  onNextButtonKeyDown: () => void;
  onChangeRouteNextButtonClick?: () => void;
  handleUpdateRegistrationData: (data: RegistrationData) => void;
  role: string;
  registerData: RegistrationData;
  onClick?: () => void;
};

type DotByStateType = {
  [key: string]: boolean;
};

const mapDispatchToProps = {
  updateRegistrationData,
};

const AddOrganization: React.FC<
  AddOrganizationProps &
    ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps
> = ({
  onNextButtonClick,
  onBackButtonClick,
  updateRegistrationData,
  registrationData,
  ...props
}) => {
  const [requiredField, setRequiredField] = useState<string>("");
  const [dotByState, setDotByState] = useState<DotByStateType>({});
  const [companyName, setCompanyName] = useState<string>("");
  const [isOrganizationsUpdated, setIsOrganizationsUpdated] =
    useState<boolean>(false);

  const handleCompanyName = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newName = e.target.value;

    setCompanyName(newName);
    if (newName == "") setIsOrganizationsUpdated(false);
    else setIsOrganizationsUpdated(true);

    const updatedOrganizations = [...registrationData.organizations];

    updatedOrganizations[index] = {
      ...updatedOrganizations[index],
      name: newName,
    };
    updateRegistrationData({
      ...registrationData,
      organizations: updatedOrganizations,
    });
  };

  const handleDotChange = (state: any, value: any) => {
    setDotByState((prevDotByState) => ({
      ...prevDotByState,
      [state]: value,
    }));
  };

  const handleNextClick = () => {
    if (!companyName) {
      const emptyField = !companyName ? "companyName" : "";
      setRequiredField(emptyField);

      return;
    }

    updateRegistrationData({
      ...registrationData,
      firstName: registrationData.firstName,
      lastName: registrationData.lastName,
    });
    onNextButtonClick();
  };

  const handleBackButtonClick = () => {
    onBackButtonClick();
  };

  const onInputWithLabel1Click = useCallback(() => {}, []);

  return (
    <div className={styles.backButtonParent}>
      <div
        className={styles.textButton}
        onClick={handleBackButtonClick}
        style={{ cursor: "pointer" }}
      >
        <img className={styles.icon} alt="" src="/generics/icon2.svg" />
        <div className={styles.reefer}>Back</div>
      </div>
      <div className={styles.stepcircleWithTextParent}>
        <div className={styles.stepcircleWithText}>
          <div className={styles.stepcircle}>
            <img className={styles.icon} alt="" src="/generics/check1.svg" />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Sign up</div>
          </div>
        </div>
        <div className={styles.componentChild} />
        <div className={styles.stepcircleWithText1}>
          <div className={styles.stepcircle1}>
            <img className={styles.icon} alt="" src="/generics/check.svg" />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Company details</div>
          </div>
        </div>
        <div className={styles.componentChild} />
        <div className={styles.stepcircleWithText2}>
          <div className={styles.stepcircle2}>
            <div className={styles.dot} />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Verification</div>
          </div>
        </div>
      </div>
      <div className={styles.userNameParent}>
        <div className={styles.userName}>Your organization details</div>
        <div className={styles.inputText3}>
          Please provide your Organization information accurately, it will be
          used in all your communications on the platform.&nbsp;
        </div>
      </div>
      <div className={styles.textButtonGroup}>
        <div className={styles.textButton1}>
          <img className={styles.icon} alt="" src="/generics/icon3.svg" />
          <div className={styles.reefer}>Back&nbsp;</div>
        </div>
        <div className={styles.frameWrapper}>
          <div className={styles.inputWithLabelParent}>
            <div
              className={styles.inputWithLabel1}
              onKeyDown={() => {}}
              role="button"
              onClick={onInputWithLabel1Click}
            >
              <div className={styles.reefer}>Company name</div>
              <div
                className={styles.dropdown}
                style={
                  requiredField === "companyName"
                    ? { border: "1px solid red" }
                    : {}
                }
              >
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Company name"
                  style={{ border: "none", paddingLeft: "10px" }}
                  onChange={(e) => handleCompanyName(e, 0)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameChild} />
        <div className={styles.frameChild} />
        <div className={styles.checkbox}>
          <div>
            <label className={styles.textDescription}>
              <input
                style={{
                  height: "19px",
                  width: "19px",
                  border: "1px solid gray",
                }}
                type="checkbox"
                name="dotRadioButton"
                checked={dotByState["State 1"] || false}
                onChange={(e) => handleDotChange("State 1", e.target.checked)}
              />
              I don't have DOT
            </label>
          </div>
        </div>
        <div
          className={`${
            isOrganizationsUpdated ? styles.button2 : styles.button1
          } hoverButtons ${
            isOrganizationsUpdated ? "" : styles.disabledButton
          }`}
          onClick={isOrganizationsUpdated ? handleNextClick : () => {}}
        >
          <div className={styles.text}>Search company</div>
        </div>
        <div className={styles.inputTextContainer}>
          <div className={styles.inputText8}>Length</div>
          <div className={styles.tagParent}>
            <div className={styles.tag}>
              <img
                className={styles.earnRevenueFromYourIdleFl}
                alt=""
                src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
              />
              <div className={styles.reefer}>Small</div>
            </div>
            <div className={styles.tag}>
              <img
                className={styles.earnRevenueFromYourIdleFl}
                alt=""
                src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
              />
              <div className={styles.reefer}>Large</div>
            </div>
          </div>
        </div>
        <div className={styles.inputTextContainer}>
          <div className={styles.inputText8}>Lift Gate</div>
          <div className={styles.tagParent}>
            <div className={styles.tag}>
              <img
                className={styles.earnRevenueFromYourIdleFl}
                alt=""
                src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
              />
              <div className={styles.reefer}>Rail Gate</div>
            </div>
            <div className={styles.tag}>
              <img
                className={styles.earnRevenueFromYourIdleFl}
                alt=""
                src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
              />
              <div className={styles.reefer}>Tuck Under</div>
            </div>
            <div className={styles.tag}>
              <img
                className={styles.earnRevenueFromYourIdleFl}
                alt=""
                src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
              />
              <div className={styles.reefer}>No Lift Gate</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  registrationData: state.registrationData,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddOrganization);

const StyledButton = styled.button`
  position: absolute;
  top: 15%;
  border: 0;
  right: 6%;
  background-color: transparent;
`;

const Container = styled.div`
  border-radius: 8px;
  box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15);
  background-color: var(--basic-white, #fff);
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  color: var(--neutral-400, #94a3b8);
  white-space: nowrap;
  align-items: start;
  border-radius: 8px;
  background-color: var(--basic-white, #fff);
  margin-top: 4px;
  width: 100%;
  justify-content: center;
  padding: 8px 60px 8px 16px;
  font: 400 14px/20px Inter, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
    padding-right: 20px;
  }
`;

const Item = styled.div`
  text-align: left;
  color: var(--neutral-900, #0f172a);
  white-space: nowrap;
  align-items: start;
  border-radius: 8px;
  background-color: var(--basic-white, #fff);
  width: 100%;
  justify-content: center;
  padding: 8px 60px 8px 16px;
  font: 400 14px/20px Inter, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
    padding-right: 20px;
  }
`;

const SelectedValue = styled.div`
  color: var(--neutral-900, #0f172a);
  white-space: nowrap;
  align-items: start;
  border-radius: 8px;
  background-color: var(--brand-100, #fedbcc);
  width: 100%;
  justify-content: center;
  padding: 8px 60px 8px 16px;
  font: 400 14px/20px Inter, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
    padding-right: 20px;
  }
`;
