import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import "./App.css";
import { useState, useEffect } from "react";
import RegistrationInitialPage from "./pages/RegistrationInitialPage.tsx";
import RegistrationInitialOwner from "./pages/RegistrationInitialOwner.tsx";
import RegistrationOwnerFirst from "./pages/RegistrationOwnerFirst.tsx";
import RegistrationInitialRenter from "./pages/RegistrationInitialRenter.tsx";
import ProductPage from "./pages/Selected_Truck/ProductPage.tsx";
import MapPage from "./pages/Map_Page/MapPage.tsx";
import MapPageNavbar from "./pages/Map_Page/MapPageNavbar.tsx";
import HomePage from "./pages/Landing/HomePage.tsx";
import AddTruckInitial from "./pages/Add_Truck/AddTruckInitial.tsx";
import OrganizationPage from "./pages/Organization_Page/OrganizationPage";
import UserAdministration from "./pages/Admin/UserAdministration.tsx";
import TruckManagement from "./pages/Admin/TruckManagement.tsx";
import Login from "./components/Login/Login.tsx";
import ForgotPassword from "./components/Login/ForgotPassword.tsx";
import MainAdministration from "./pages/Admin/MainAdministration.tsx";
import NotFoundPage from "./pages/NotFound/NotFound.tsx";
import ProtectedRoute from "./components/Route/ProtectedRoute.tsx";
import NoAuthRestrictedRoute from "./components/Route/NoAuthRestrictedRoute.tsx";
import Reservations from "./pages/Admin/ads/Reservations.tsx";
import Profile from "./pages/Admin/settings/Profile.tsx";
import store from "./store/store";
import Toast from "./components/Toast/Toast.tsx";
import Footer from "./pages/Map_Page/Footer.tsx";
import { GlobalProvider } from "./components/Global/GlobalState.tsx";

function App() {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          localStorage.setItem(
            "userLocation",
            JSON.stringify({ latitude, longitude })
          );
          console.log("User location saved:", { latitude, longitude });
        },
        (err) => {
          setError(err.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    <Provider store={store}>
      <GlobalProvider>
        <Toast />
        <Router>
          <div
            className="App"
            style={{ overflowY: "hidden", backgroundColor: "#fff" }}
          >
            {/* simple routes */}
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route
                path="/main"
                element={
                  <>
                    <MapPageNavbar />
                    <MapPage />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/truck/:id"
                element={
                  <>
                    <MapPageNavbar />
                    <ProductPage />
                  </>
                }
              />
              <Route
                path="/organization/:id"
                element={
                  <>
                    <MapPageNavbar />
                    <OrganizationPage />
                  </>
                }
              />

              {/* No auth Restricted routes */}
              <Route
                path="/login"
                element={<NoAuthRestrictedRoute cmp={Login} />}
              />
              <Route
                path="/forgot_password"
                element={<NoAuthRestrictedRoute cmp={ForgotPassword} />}
              />

              <Route
                path="/registration_driver"
                element={
                  <NoAuthRestrictedRoute cmp={RegistrationInitialRenter} />
                }
              />
              <Route
                path="/registration_driver/sign_up"
                element={
                  <NoAuthRestrictedRoute cmp={RegistrationInitialPage} />
                }
              />
              <Route
                path="/registration_owner"
                element={
                  <NoAuthRestrictedRoute cmp={RegistrationInitialOwner} />
                }
              />
              <Route
                path="/registration_owner/sign_up"
                element={<NoAuthRestrictedRoute cmp={RegistrationOwnerFirst} />}
              />

              {/* PROTECTED ROUTES */}
              <Route
                path="/add_truck"
                element={<ProtectedRoute cmp={AddTruckInitial} />}
              />
              <Route
                path="/admin/user_administration"
                element={<ProtectedRoute cmp={UserAdministration} />}
              />
              <Route
                path="/admin"
                element={<ProtectedRoute cmp={MainAdministration} />}
              />
              <Route
                path="/admin/truck_management"
                element={<ProtectedRoute cmp={TruckManagement} />}
              />
              <Route
                path="/admin/ads/reservations"
                element={<ProtectedRoute cmp={Reservations} />}
              />
              <Route
                path="/admin/settings/profile"
                element={<ProtectedRoute cmp={Profile} />}
              />

              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </Router>
      </GlobalProvider>
    </Provider>
  );
}

export default App;
