import { FunctionComponent, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import styles from "./css/UserAdministration.module.css";
import AdminSidebar from "../../pages/Admin/AdminSidebar";
import AddTruckInitial from "../Add_Truck/AddTruckInitial";
import GenericModal from "../../components/Modal/GenericModal";
import LocationModalBody from "./LocationModalBody";
import { TruckRegistrationData } from "../../store/types";
import { useSelector } from "react-redux";
import DeleteForever from "@mui/icons-material/DeleteForever";
import axios from "axios";
import { errorToast, successToast } from "../../components/Toast/ToastMessage";
import Loader from "../../components/Loader/Loader";
import AdminNavBar from "./AdminNavBar";
import { getAuthHeaders } from "../../utils/GetHeaders";
import {
  REACT_APP_ORG_API_BASE_URL,
  REACT_APP_PAGINATION_OFFSET,
  REACT_APP_AUTH_SVC_API_BASE_URL,
  REACT_APP_TRUCK_API_BASE_URL,
} from "../../utils/UrlConstants";
import { useGlobalContext } from "../../components/Global/GlobalState";

export interface Column {
  id: string;
  name: string;
}

export interface TableTypes {
  columns: Column[];
  rows: { [key: string]: string | void }[];
}

const TruckManagement: FunctionComponent<TruckRegistrationData> = (
  TruckRegistrationData,
  handleUpdateTruckRegistrationData
) => {
  const reduxState = useSelector((state: any) => state);
  const [idToUpdate, setIdToUpdate] = useState<TruckRegistrationData>();
  const [truckRegistrationDataToUpdate, setTruckRegistrationDataToUpdate] =
    useState<TruckRegistrationData>({} as TruckRegistrationData);
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => setOpen(false);
  const { sideBarToggle, setSideBarToggle } = useGlobalContext();

  const [rows, setRows] = useState<{ [key: string]: string }[]>([]);
  const [filteredRows, setFilteredRows] = useState<{ [key: string]: string }[]>(
    [{}]
  );
  const [filter, setFilter] = useState<string>("all");
  const [page, pageChange] = useState<number>(0);
  const [rowPerPage, setRowPerPage] = useState<number>(5);
  const [loading, setloading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [trucks, setTrucks] = useState<TruckRegistrationData[]>([]);
  const [trucksImages, setTrucksImages] = useState<
    TruckRegistrationData[] | any
  >([]);
  const [fullDataCount, setfullDataCount] = useState<number>(0);
  const [userRole, setUserRole] = useState<string>("");
  const [userTrucks, setUserTrucks] = useState<any>([]);
  const [userOrganizations, setUserOrganizations] = useState<string>("");
  const [isFetched, setIsFetched] = useState<boolean>(false);

  const [truckActivated, setTruckActivated] = useState<number>(0);

  const [openLocationEdit, setOpenLocationEdit] = useState<boolean>(false);
  const handleOpenLocationEdit = async () => {
    setOpenLocationEdit(true);
  };
  const handleCloseLocationEdit = () => setOpenLocationEdit(false);

  //PAGINATION
  const offset: number = parseInt(REACT_APP_PAGINATION_OFFSET || "8");

  useEffect(() => {
    if (userOrganizations) fetchUserTrucks(userOrganizations);
    console.log(userOrganizations);
    console.log("Here ^");
  }, [userOrganizations]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchUser();
      if (userRole === "admin") {
        fetchTrucks();
      } else {
        if (isFetched) {
          fetchOrganizationTrucks(reduxState.organization._id);
        }
      }
    };

    fetchData();
  }, [currentPage, userOrganizations, truckActivated]);

  useEffect(() => {
    console.log("truck vvv");
    console.log(trucks);
  }, [trucks]);

  const columns: Column[] = [
    { id: "_id", name: "_id" },
    { id: "name", name: "NAME" },
    { id: "status", name: "STATUS" },
    { id: "year", name: "YEAR" },
    // { id: "lastInvoice", name: "LAST INVOICE" },
    { id: "earnings", name: "RENT/DAY" },
    { id: "location", name: "LOCATION" },
    { id: "adStatus", name: "AGREEMENT TYPE" },
    { id: "activate", name: "" },
    { id: "edit", name: "" },
    { id: "delete", name: "" },
  ];

  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const fetchUserTrucks = async (id: string) => {
    setloading(true);
    var url = `${REACT_APP_ORG_API_BASE_URL}/organization/${id}/trucks?page=1?pageSize=10`;
    try {
      const response = await axios.get(url);

      console.log("fetch user trucks");
      console.log(response.data);
      let trucks = response.data.trucks;

      const formattedTrucks = trucks.map((truck: TruckRegistrationData) => ({
        _id: truck._id,
        name: capitalize(truck.manufacturer),
        status: truck.status,
        year: truck.year.toString(),
        earnings: "$" + truck.rentPerDay.toString(),
        adStatus: (() => {
          const rent = truck.enableForRent ? "R" : "";
          const lease = truck.enableForLease ? "L" : "";
          const leaseForPurchase = truck.enableForLeaseForPurchase ? "LTP" : "";

          const status = [rent, lease, leaseForPurchase]
            .filter(Boolean)
            .join("/");

          return status || "None";
        })(),
        image:
          Object.values(truck?.images || {}).find(
            (value) => value && value.trim() !== ""
          ) || "/admin/truck_image.png",
        location: `${truck.state.toUpperCase()}, ${capitalize(truck.city)}, ${
          truck.zipCode
        }`,
      }));

      setRows(formattedTrucks);
      setFilteredRows(formattedTrucks);
      setTrucks(response.data.trucks);
      setfullDataCount(response.data.recordsFound);
      setloading(false);
    } catch (error) {
      console.error(error, error);
    }
  };

  const handleDeleteTruck = async (id: string) => {
    const isConfirmed = confirm("Are you sure you want to delete this truck?");
    if (isConfirmed) {
      await deleteTruck(id);
      fetchTrucks();
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    pageChange(newPage);
  };

  const handleRowsPerPage = (event: any) => {
    setRowPerPage(event.target.value);
    pageChange(0);
  };

  const handleOpen = async (id: string) => {
    await fetchEditingTruck(id);
  };

  const handleActivateTruck = async (id: string, status: string) => {
    console.log(status);
    if (status === "inrent") {
      errorToast("Truck is in rent and cannot be deactivated");
    } else {
      activateTruck(id, status);
    }
  };

  const fetchUser = async () => {
    var url = `${REACT_APP_AUTH_SVC_API_BASE_URL}/me`;
    try {
      console.log(url);
      const response = await axios.get(url, {
        headers: getAuthHeaders(),
      });
      console.log(response.data.user);
      setUserRole(response.data.user.role);
      setUserOrganizations(response.data.user.organizations[0]);
      setIsFetched(true);
    } catch (error) {
      console.error(error, error);
    }
  };

  const activateTruck = async (id: string, status: string) => {
    console.log(id);
    const url = `${REACT_APP_TRUCK_API_BASE_URL}/trucks/${id}`;

    try {
      console.log(url);

      const response = await axios.patch(
        url,
        {
          status: status,
        },
        {
          headers: {
            ...getAuthHeaders(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.error) {
        errorToast(response.data.error);
      } else {
        successToast("Truck switched status successfully");
        setTruckActivated((prev) => prev + 1);
      }
    } catch (error: any) {
      console.log(error.response?.status);

      if (error.response?.data?.error) {
        errorToast(error.response.data.error);
      } else if (error.response?.status === 401) {
        errorToast("Unauthorized 401");
      } else {
        errorToast("An error occurred");
      }
    }
  };

  const handleRowClick = (id: string) => (e: React.MouseEvent) => {
    if (e.target instanceof HTMLElement) {
      const clickedId = e.target.id;
      if (
        clickedId !== "edit" &&
        clickedId !== "delete" &&
        clickedId !== "activate" &&
        clickedId !== "location"
      ) {
        window.open(`#/truck/${id}`, "_blank");
      }
    }
  };

  const deleteTruck = async (id: string) => {
    var url = `${REACT_APP_TRUCK_API_BASE_URL}/trucks/${id}`;
    try {
      console.log(url);
      const response = await axios.delete(url, {
        headers: getAuthHeaders(),
      });
      successToast("Truck removed successfully");
    } catch (error) {
      errorToast("Error while removing truck");
      console.error(error, error);
    }
  };

  const [selectedTruck, setSelectedTruck] =
    useState<TruckRegistrationData | null>(null);

  const handleChangeLocation = async (id: string) => {
    const truck = trucks.find((truck) => truck._id === id);
    if (truck) {
      setSelectedTruck(truck);
      handleOpenLocationEdit();
    }
  };

  const updateLocation = async (
    id: string,
    state: string,
    city: string,
    zipCode: string
  ) => {
    const url = `${REACT_APP_TRUCK_API_BASE_URL}/trucks/${id}`;
    try {
      console.log(url);
      const response = await axios.patch(
        url,
        {
          state: state,
          city: city,
          zipCode: zipCode,
        },
        {
          headers: getAuthHeaders(), // Include headers in the config object
        }
      );

      console.log(response);

      if (response.status === 200) {
        successToast("Truck updated successfully");
        handleCloseLocationEdit();
        window.location.reload();
      } else {
        errorToast("Error while updating truck");
      }
    } catch (error) {
      errorToast("Error while updating truck");
      console.error(error);
    }
  };

  const fetchEditingTruck = async (id: string) => {
    var url = `${REACT_APP_TRUCK_API_BASE_URL}/trucks/${id}`;
    try {
      const response = await axios.get(url);
      setIdToUpdate(response.data.truck[0]);
      setTruckRegistrationDataToUpdate(response.data.truck);
      setOpen(true);
    } catch (error) {
      console.error(error, error);
    }
  };

  const fetchTrucks = async (query: any = null) => {
    var url = `${REACT_APP_TRUCK_API_BASE_URL}/trucks?offset=${
      offset * page
    }&page=${page}`;
    if (query != null) {
      url = `${REACT_APP_TRUCK_API_BASE_URL}/trucks?offset=${offset}&page=${
        query.page || 1
      }`;
    }
    try {
      const response = await axios.get(
        url?.replace(/ /g, "")?.replace(/,,/g, "")?.replace(/&&/g, "&")
      );

      let trucks = response.data.trucks;
      trucks = trucks.reverse();

      let trucks2 = trucks;
      setTrucksImages(trucks2);

      const formattedTrucks = trucks.map((truck: TruckRegistrationData) => ({
        _id: truck._id,
        name: capitalize(truck.manufacturer),
        status: truck.status,
        year: truck.year.toString(),
        lastInvoice: "No Invoice",
        image:
          Object.values(truck?.images || {}).find(
            (value) => value && value.trim() !== ""
          ) || "/admin/truck_image.png",
        earnings: "$" + truck.rentPerDay.toString(),
        location:
          (truck?.state?.toUpperCase() ?? "") +
          " " +
          (truck?.city ?? "") +
          " " +
          (truck?.zipCode ?? ""),
        adStatus: (() => {
          const rent = truck.enableForRent ? "R" : "";
          const lease = truck.enableForLease ? "L" : "";
          const leaseForPurchase = truck.enableForLeaseForPurchase ? "LTP" : "";

          const status = [rent, lease, leaseForPurchase]
            .filter(Boolean)
            .join("/");

          return status || "None";
        })(),
      }));
      setRows(formattedTrucks);
      setFilteredRows(formattedTrucks);
      setTrucks(response.data.trucks);
      setfullDataCount(response.data.recordsFound);
      setloading(false);
    } catch (error) {
      setTrucks([]);
      setfullDataCount(0);
      setloading(false);
      errorToast("Error fetching trucks");
      console.error("Error fetching trucks:", error);
    }
  };

  const filterUsers = (filter: string) => {
    pageChange(0);
    let filteredData: any;
    switch (filter) {
      case "all":
        setFilteredRows(rows);
        setFilter("all");
        break;
      case "active":
        filteredData = rows.filter((row) => row.status === "active");
        setFilter("active");
        setFilteredRows(filteredData);
        break;
      case "pending":
        filteredData = rows.filter((row) => row.status === "pending");
        setFilter("pending");
        setFilteredRows(filteredData);
        break;
      case "onhold":
        filteredData = rows.filter((row) => row.status === "onhold");
        setFilter("onhold");
        setFilteredRows(filteredData);
        break;
      case "hidden":
        filteredData = rows.filter((row) => row.status === "hidden");
        setFilter("hidden");
        setFilteredRows(filteredData);
        break;
      case "inrent":
        filteredData = rows.filter((row) => row.status === "inrent");
        setFilter("inrent");
        setFilteredRows(filteredData);
        break;
      default:
        setFilteredRows(rows);
        setFilter("all");
        break;
    }
  };

  const fetchOrganizationTrucks = async (id: string | null = null) => {
    var url = `${REACT_APP_ORG_API_BASE_URL}/organization/${id}/trucks?page=1?pageSize=50`;
    try {
      const response = await axios.get(url);

      setUserTrucks(response.data.trucks);

      let trucks = response.data.trucks;

      const formattedTrucks = trucks.map((truck: TruckRegistrationData) => ({
        _id: truck._id,
        name: truck.manufacturer,
        status: truck.status,
        year: truck.year.toString(),
        lastInvoice: "not found",
        earnings: "$" + truck.rentPerDay.toString(),
        image:
          Object.values(truck?.images || {}).find(
            (value) => value && value.trim() !== ""
          ) || "/admin/truck_image.png",
        location:
          (truck?.state ?? "") +
          " " +
          (truck?.city ?? "") +
          " " +
          (truck?.zipCode ?? ""),
        adStatus: (() => {
          const rent = truck.enableForRent ? "R" : "";
          const lease = truck.enableForLease ? "L" : "";
          const leaseForPurchase = truck.enableForLeaseForPurchase ? "LTP" : "";

          const status = [rent, lease, leaseForPurchase]
            .filter(Boolean)
            .join("/");

          return status || "None";
        })(),
      }));

      setRows(formattedTrucks);

      console.log("User trucks (rows) vv");
      console.log(rows);

      setTrucks(response.data.trucks);
      setfullDataCount(response.data.recordsFound);
      setloading(false);
    } catch (error) {
      setfullDataCount(0);
      setloading(false);
      errorToast("Error fetching organization");
      errorToast("No trucks");
      console.error("Error fetching organization:", error);
    }
  };

  const filteredColumns = columns.filter((column) => {
    if (
      userRole !== "admin" &&
      userRole !== "renter" &&
      (column.id === "edit" ||
        column.id === "delete" ||
        column.id === "activate")
    ) {
      return false;
    }
    return true;
  });

  return (
    <>
      <div className={styles.adminNavBar}>
        <AdminNavBar />
      </div>
      <div
        className="d-flex justify-content-between"
        style={{ width: "100%", height: "100vh" }}
      >
        <div className={styles.adminSideBar}>
          <AdminSidebar />
        </div>
        <div className={styles.userAdministration}>
          <div className={styles.textParent}>
            <div
              className={styles.text15}
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Link to="/admin">
                  <span>Settings /</span>
                </Link>
                <span className={styles.userAdministration1}>
                  {" "}
                  Asset Management
                </span>
              </div>

              <Link to="/add_truck">
                <div className={`${styles.button2} hoverButtons`}>
                  <img
                    className={styles.iconoutlineuserAdd}
                    alt=""
                    style={{ filter: "contrast(0%) brightness(2)" }}
                    src="/truck/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1-4@2x.png"
                  />
                  <div className={styles.text17} style={{ fontSize: "14px" }}>
                    Add truck
                  </div>
                </div>
              </Link>
            </div>

            <div className={styles.numberCardParent}>
              <div className={styles.numberCard}>
                <div className={styles.creditsUsedParent}>
                  <div className={styles.creditsUsed}>Published Trucks</div>
                  <img
                    className={styles.iconoutlinequestionMarkCir1}
                    alt=""
                    src="/admin/main/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.div}>{trucks.length} trucks</div>
                <div className={styles.numberCardInner}>
                  <div className={styles.frameChild} />
                </div>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.creditsUsedGroup}>
                  <div className={styles.creditsUsed1}>
                    Vechicles Incomplete
                  </div>
                  <img
                    className={styles.iconoutlinequestionMarkCir1}
                    alt=""
                    src="/admin/main/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.div1}>
                  {" "}
                  {
                    trucks.filter((truck) => truck.status === "incomplete")
                      .length
                  }
                </div>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.creditsUsedGroup}>
                  <div className={styles.creditsUsed1}>Vechicles Frozen</div>
                  <img
                    className={styles.iconoutlinequestionMarkCir1}
                    alt=""
                    src="/admin/main/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.div1}>
                  {" "}
                  {trucks.filter((truck) => truck.status === "frozen").length}
                </div>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.creditsUsedGroup}>
                  <div className={styles.creditsUsed1}>Vechicles on Hold</div>
                  <img
                    className={styles.iconoutlinequestionMarkCir1}
                    alt=""
                    src="/admin/main/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.div1}>
                  {trucks.filter((truck) => truck.status === "onhold").length}
                </div>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.creditsUsedGroup}>
                  <div className={styles.creditsUsed1}>Vechicles Hidden</div>
                  <img
                    className={styles.iconoutlinequestionMarkCir1}
                    alt=""
                    src="/admin/main/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.div1}>
                  {" "}
                  {trucks.filter((truck) => truck.status === "hidden").length}
                </div>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.creditsUsedGroup}>
                  <div className={styles.creditsUsed1}>Vechicles in Rent</div>
                  <img
                    className={styles.iconoutlinequestionMarkCir1}
                    alt=""
                    src="/admin/main/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.div1}>
                  {" "}
                  {trucks.filter((truck) => truck.status === "inrent").length}
                </div>
              </div>
            </div>
            <div className={styles.tabExampleLightDesktopParent}>
              <div className={styles.tabExampleLightDesktop}>
                <div className={styles.tabExampleLightDesktop1}>
                  <div className={styles.tabs}>
                    <div
                      className={styles.desktopTab2}
                      style={
                        filter === "all"
                          ? {
                              borderBottom: "2px solid #fa6f32",
                              color: "#fa6f32",
                            }
                          : {}
                      }
                    >
                      <div
                        className={styles.tab1Wrapper}
                        onClick={() => {
                          filterUsers("all");
                        }}
                      >
                        <div className={styles.tab3}>All</div>
                      </div>
                      <div className={styles.desktopTabInner} />
                    </div>
                    <div
                      className={styles.desktopTab2}
                      style={
                        filter === "active"
                          ? {
                              borderBottom: "2px solid #fa6f32",
                              color: "#fa6f32",
                            }
                          : {}
                      }
                    >
                      <div
                        className={styles.tab3Wrapper}
                        onClick={() => {
                          filterUsers("active");
                        }}
                      >
                        <div className={styles.tab3}>Active</div>
                      </div>
                      <div className={styles.desktopTabInner} />
                    </div>
                    <div
                      className={styles.desktopTab2}
                      style={
                        filter === "pending"
                          ? {
                              borderBottom: "2px solid #fa6f32",
                              color: "#fa6f32",
                            }
                          : {}
                      }
                    >
                      <div
                        className={styles.tab3Wrapper}
                        onClick={() => {
                          filterUsers("pending");
                        }}
                      >
                        <div className={styles.tab3}>Pending</div>
                      </div>
                      <div className={styles.desktopTabInner} />
                    </div>
                    <div
                      className={styles.desktopTab2}
                      style={
                        filter === "onhold"
                          ? {
                              borderBottom: "2px solid #fa6f32",
                              color: "#fa6f32",
                            }
                          : {}
                      }
                    >
                      <div
                        className={styles.tab4Wrapper}
                        onClick={() => {
                          filterUsers("onhold");
                        }}
                      >
                        <div className={styles.tab4}>On hold</div>
                      </div>
                      <div className={styles.desktopTabChild1} />
                    </div>
                    <div
                      className={styles.desktopTab2}
                      style={
                        filter === "hidden"
                          ? {
                              borderBottom: "2px solid #fa6f32",
                              color: "#fa6f32",
                            }
                          : {}
                      }
                    >
                      <div
                        className={styles.tab5Wrapper}
                        onClick={() => {
                          filterUsers("hidden");
                        }}
                      >
                        <div className={styles.tab5}>Hidden</div>
                      </div>
                      <div className={styles.desktopTabChild2} />
                    </div>
                    <div
                      className={styles.desktopTab2}
                      style={
                        filter === "inrent"
                          ? {
                              borderBottom: "2px solid #fa6f32",
                              color: "#fa6f32",
                            }
                          : {}
                      }
                    >
                      <div
                        className={styles.tab6Wrapper}
                        onClick={() => {
                          filterUsers("inrent");
                        }}
                      >
                        <div className={styles.tab6}>In rent</div>
                      </div>
                      <div className={styles.desktopTabChild3} />
                    </div>
                  </div>
                  <div className={styles.underLine} />
                </div>
              </div>
              <div className={styles.frameDiv}>
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer sx={{ minHeight: 450, maxHeight: 1000 }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              {filteredColumns.map(
                                (column, key) =>
                                  column.name !== "_id" && (
                                    <TableCell
                                      style={{
                                        backgroundColor: "#F8FAFC",
                                        fontWeight: "600",
                                        color: "gray",
                                        textAlign:
                                          column.id !== "name"
                                            ? "center"
                                            : "inherit",
                                      }}
                                      key={key}
                                    >
                                      {column.name}
                                    </TableCell>
                                  )
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredRows &&
                              filteredRows
                                .slice(
                                  page * rowPerPage,
                                  page * rowPerPage + rowPerPage
                                )
                                .map((row, rowIndex, trucksImages) => (
                                  <TableRow
                                    key={rowIndex}
                                    className={styles.tableRow}
                                    onClick={handleRowClick(row._id)}
                                  >
                                    {columns &&
                                      columns
                                        .filter((column) => column.id !== "_id")
                                        .map((column) => {
                                          const value = row[column.id];

                                          return (
                                            <TableCell
                                              key={`${row._id}-${column.id}`}
                                              style={{
                                                textAlign:
                                                  column.id !== "name"
                                                    ? "center"
                                                    : "inherit",
                                                padding:
                                                  column.id === "activate" ||
                                                  column.id === "edit" ||
                                                  column.id === "delete"
                                                    ? "16px 0"
                                                    : "16px",
                                              }}
                                            >
                                              {column.id === "name" && (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <img
                                                    // src="/admin/truck_image.png"
                                                    src={row.image}
                                                    alt="Avatar"
                                                    style={{
                                                      marginRight: "5px",
                                                      width: "4vh",
                                                      height: "4vh",
                                                    }}
                                                  />
                                                  <div>
                                                    <span
                                                      style={{
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {row.name}
                                                    </span>
                                                    <br />
                                                    <span
                                                      style={{
                                                        textTransform:
                                                          "capitalize",
                                                      }}
                                                    >
                                                      {row.status}
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                              {column.id !== "name" && (
                                                <span
                                                  className={
                                                    column.id === "location"
                                                      ? "hoverButtons"
                                                      : ""
                                                  }
                                                  onClick={
                                                    column.id === "edit"
                                                      ? (e) => {
                                                          e.stopPropagation();
                                                          handleOpen(row._id);
                                                        }
                                                      : column.id === "activate"
                                                      ? (e) => {
                                                          e.stopPropagation();
                                                          row.status ===
                                                          "active"
                                                            ? handleActivateTruck(
                                                                row._id,
                                                                "onhold"
                                                              )
                                                            : handleActivateTruck(
                                                                row._id,
                                                                "active"
                                                              );
                                                        }
                                                      : column.id === "delete"
                                                      ? () =>
                                                          handleDeleteTruck(
                                                            row._id
                                                          )
                                                      : column.id === "location"
                                                      ? (e) => {
                                                          e.stopPropagation();

                                                          handleChangeLocation(
                                                            row._id
                                                          );
                                                        }
                                                      : undefined
                                                  }
                                                  style={{
                                                    textTransform: "capitalize",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    borderRadius: "16px",
                                                    padding: "4%",
                                                    backgroundColor:
                                                      value === "active"
                                                        ? "orange"
                                                        : value === "incomplete"
                                                        ? "#2bbdbd"
                                                        : value === "frozen"
                                                        ? "gray"
                                                        : value === "onhold"
                                                        ? "#8282e9"
                                                        : value === "inrent"
                                                        ? "#3fab4e"
                                                        : "none",
                                                    color:
                                                      column.id === "edit"
                                                        ? "#fa6f32"
                                                        : column.id === "delete"
                                                        ? "red"
                                                        : column.id ===
                                                          "activate"
                                                        ? "#32a852"
                                                        : column.id ===
                                                          "location"
                                                        ? "#fa6f32"
                                                        : value === "activate"
                                                        ? "white"
                                                        : value === "inrent"
                                                        ? "white"
                                                        : value ===
                                                            "incomplete" ||
                                                          value === "driver" ||
                                                          value === "active" ||
                                                          value === "onhold" ||
                                                          value === "frozen"
                                                        ? "white"
                                                        : "inherit",

                                                    zIndex:
                                                      column.id === "edit" ||
                                                      column.id === "activate"
                                                        ? "999"
                                                        : "1",
                                                    fontWeight:
                                                      column.id === "edit" ||
                                                      column.id === "delete"
                                                        ? "bold"
                                                        : "normal",
                                                  }}
                                                >
                                                  {value === "onhold"
                                                    ? "on hold"
                                                    : value === "inrent"
                                                    ? "in rent"
                                                    : value}

                                                  {column.id === "activate" &&
                                                  (userRole === "admin" ||
                                                    userRole === "renter")
                                                    ? row.status === "active"
                                                      ? "Deactivate"
                                                      : "Activate"
                                                    : ""}

                                                  {column.id === "edit" &&
                                                  (userRole === "admin" ||
                                                    userRole === "renter") ? (
                                                    <svg
                                                      className="feather feather-edit"
                                                      fill="none"
                                                      height="17"
                                                      width="17"
                                                      stroke="#808080"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      stroke-width="2"
                                                      viewBox="0 0 24 24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                                                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                                                    </svg>
                                                  ) : (
                                                    ""
                                                  )}

                                                  {column.id === "delete" &&
                                                  (userRole === "admin" ||
                                                    userRole === "renter") ? (
                                                    <DeleteForever />
                                                  ) : (
                                                    ""
                                                  )}
                                                </span>
                                              )}
                                            </TableCell>
                                          );
                                        })}
                                  </TableRow>
                                ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        className={styles.tablePagination}
                        sx={{ display: "flex", alignItems: "baseline" }}
                        rowsPerPageOptions={[5, 10, 25]}
                        page={page}
                        rowsPerPage={rowPerPage}
                        component="div"
                        count={filteredRows.length}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleRowsPerPage}
                      ></TablePagination>
                    </Paper>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <GenericModal
        cmp={AddTruckInitial}
        setShowModal={handleClose}
        showModal={open}
        onClickSubmit={handleClose}
        showFooter={false}
        title="Edit Truck"
        cmpProps={{
          TruckRegistrationData,
          handleUpdateTruckRegistrationData: {
            handleUpdateTruckRegistrationData,
          },
          showSidebar: false,
          autoClick: true,
          idToUpdate: idToUpdate,
        }}
      />

      <GenericModal
        cmp={LocationModalBody}
        setShowModal={handleCloseLocationEdit}
        showModal={openLocationEdit}
        onClickSubmit={handleCloseLocationEdit}
        showFooter={false}
        title="Edit Location"
        cmpProps={{
          selectedTruck,
          setSelectedTruck,
          updateLocation,
        }}
      />
    </>
  );
};

export default TruckManagement;

const style = {
  position: "relative",
  overflow: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  maxHeight: "95vh",

  bgcolor: "#f8fafc",
  border: "1px solid #fff",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
  "& > form > div": {
    width: "unset",
  },
  "& > form": {
    backgroundColor: "unset",
  },
  "& > div > div": {
    left: "0",
    width: "unset",
    margin: "auto",
  },
  "@media (max-width: 750px)": {
    width: "90%",
    maxHeight: "90vh",
    padding: "2rem",
  },
  "@media (max-width: 500px)": {
    width: "100%",
    maxHeight: "100vh",
    padding: "1rem",
  },
};
