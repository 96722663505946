import { FunctionComponent, useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./css/AddTruckExpirationDate2.module.css";
import { updateTruckRegistrationData } from "../../store/actions/registrationActions";
import { TruckRegistrationData, RootStateTruckAdd } from "../../store/types";
import { connect, useSelector } from "react-redux";
import { PropsFromReduxAddTruckToRent } from "./AddTruckApplyToRent";
import { errorToast, successToast } from "../../components/Toast/ToastMessage";
import { getAuthHeaders } from "../../utils/GetHeaders";
import { REACT_APP_UPLOAD_API_BASE_URL } from "../../utils/UrlConstants";

type SelectedImages = {
  registrationFile: File | null;
  insurranceFile: File | null;
  inspectionFile: File | null;
};

export type AddtruckExpirationDate2 = {
  handleUpdateTruckRegistrationData: (data: TruckRegistrationData) => void;
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  setStartDate: (value: Date) => void;
  setEndDate: (value: Date) => void;
  setInsurranceStartDate: (value: Date) => void;
  setInsurranceEndDate: (value: Date) => void;
  setinspectionDate: (value: Date) => void;
  setFileNames: (value: any) => void;
  truckRegData: TruckRegistrationData;
  startDate: Date;
  endDate: Date;
  insurranceStartDate: Date;
  insurranceEndDate: Date;
  inspectionDate: Date;
  fileNames: any;
  idToUpdate?: TruckRegistrationData;
  currentStep: number;
} & PropsFromReduxAddTruckToRent;

const mapDispatchToProps = {
  updateTruckRegistrationData,
};

const mapStateToProps = (state: RootStateTruckAdd) => ({
  truckAddRegistrationData: state.truckRegistrationData,
});

const connectorAddTruckExpirationDate2 = connect(
  mapStateToProps,
  mapDispatchToProps
);

const AddtruckExpirationDate2: FunctionComponent<AddtruckExpirationDate2> = ({
  onNextButtonClick,
  truckAddRegistrationData,
  updateTruckRegistrationData,
  onBackButtonClick,
  setStartDate,
  setEndDate,
  setInsurranceStartDate,
  setInsurranceEndDate,
  setinspectionDate,
  setFileNames,
  startDate,
  endDate,
  insurranceStartDate,
  insurranceEndDate,
  inspectionDate,
  fileNames,
  idToUpdate,
  currentStep,
}) => {
  const [selectedImages, setSelectedImages] = useState<SelectedImages>({
    registrationFile: null,
    insurranceFile: null,
    inspectionFile: null,
  });
  const formatDateToString = (date: Date | null | undefined): string => {
    if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
      return ""; // Return an empty string for invalid or null dates
    }

    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${month}-${day}-${year}`;
  };
  const moment = require("moment");
  const fileInputRef1 = useRef<HTMLInputElement | null>(null);
  const fileInputRef2 = useRef<HTMLInputElement | null>(null);
  const fileInputRef3 = useRef<HTMLInputElement | null>(null);
  const [isFileChanged, setIsFileChanged] = useState<boolean>(false);
  const [manualStartDateDateInput, setManualStartDateDateInput] =
    useState<string>("");
  const [manualEndDateDateInput, setManualEndDateDateInput] =
    useState<string>("");
  const [manualInStartDateInput, setManualInStartDateInput] =
    useState<string>("");
  const [manualInEndDateInput, setManualInEndDateInput] = useState<string>("");
  const [manualInspectionDateInput, setManualInspectionDateInput] =
    useState<string>("");

  const handleDivClick = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.click();
    }
  };

  useEffect(() => {
    const singleImageUpload = async () => {
      const formData = new FormData();

      Object.entries(selectedImages).forEach(([fieldName, file]) => {
        if (file) {
          formData.append(fieldName, file);
        }
      });

      try {
        const response = await fetch(
          `${REACT_APP_UPLOAD_API_BASE_URL}/upload/truck/files`,
          {
            method: "POST",
            headers: getAuthHeaders(),
            body: formData,
          }
        ).then((r) => r.json());
        if (response.fileLinks) {
          successToast("Images uploaded successfully");
          console.log("Images uploaded successfully:", response);
          const updatedRegistrationData: Partial<TruckRegistrationData> = {
            ...truckAddRegistrationData,
            files: {
              ...(truckAddRegistrationData?.images || {}),
              vehicleRegistrationFile: response.fileLinks.registrationFile,
              vehicleInsuranceFile: response.fileLinks.insurranceFile,
              technicalInspectionFile: response.fileLinks.inspectionFile,
            },
          };

          updateTruckRegistrationData(updatedRegistrationData);
        } else {
          errorToast("Failed to upload image");
        }
      } catch (error: any) {
        errorToast(`Error uploading image: ${error.message}`);
      }
    };

    if (isFileChanged) singleImageUpload();
    setIsFileChanged(false);
  }, [selectedImages, isFileChanged]);

  const handleNextClick = async () => {
    console.log(selectedImages);
    console.log("^here");

    // Check if insurranceFile and registrationFile are present
    if (!selectedImages.registrationFile && !idToUpdate?.files?.vehicleRegistrationFile) {
      errorToast(
        "Please upload registration file."
      );

      console.log(idToUpdate?.files.vehicleRegistrationFile)
      console.log(selectedImages);
      return; // Prevent onNextButtonClick from being called
    }

    onNextButtonClick(); // Proceed only if the condition is met
  };
  useEffect(() => {
    handleStartDate();
  }, [startDate]);

  useEffect(() => {
    handleEndDate();
  }, [endDate]);

  useEffect(() => {
    handleinspectionDate();
  }, [inspectionDate]);

  useEffect(() => {
    handleInsurranceStartDate();
  }, [insurranceStartDate]);

  useEffect(() => {
    handleInsurranceEndDate();
  }, [insurranceEndDate]);

  const handleStartDate = () => {
    console.log(reduxState);
    const formattedStartDate = formatDateToString(startDate);

    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      registrationStartDate: formattedStartDate,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleinspectionDate = () => {
    const formattedDate = formatDateToString(inspectionDate);

    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      inspectionDate: formattedDate,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleStartDateInputBlur = () => {
    if (typeof manualStartDateDateInput != "string") return;
    const [month, day, year] = manualStartDateDateInput.split("/").map(Number);
    if (month && day && year) {
      const parsedDate = new Date(year, month - 1, day);
      setStartDate(parsedDate);
    }
  };

  const handleEndDateInputBlur = () => {
    if (typeof manualEndDateDateInput != "string") return;
    const [month, day, year] = manualEndDateDateInput.split("/").map(Number);
    if (month && day && year) {
      const parsedDate = new Date(year, month - 1, day);
      setEndDate(parsedDate);
    }
  };

  const handleInsurranceStartDateInputBlur = () => {
    if (typeof manualInStartDateInput != "string") return;
    const [month, day, year] = manualInStartDateInput.split("/").map(Number);
    if (month && day && year) {
      const parsedDate = new Date(year, month - 1, day);
      setInsurranceStartDate(parsedDate);
    }
  };

  const handleInsurranceEndDateInputBlur = () => {
    if (typeof manualInEndDateInput != "string") return;
    const [month, day, year] = manualInEndDateInput.split("/").map(Number);
    if (month && day && year) {
      const parsedDate = new Date(year, month - 1, day);
      setInsurranceEndDate(parsedDate);
    }
  };

  const handleInspectionDateInputBlur = () => {
    if (typeof manualInspectionDateInput != "string") return;
    const [month, day, year] = manualInspectionDateInput.split("/").map(Number);
    if (month && day && year) {
      const parsedDate = new Date(year, month - 1, day);
      setinspectionDate(parsedDate);
    }
  };

  const handleEndDate = () => {
    const formattedDate = formatDateToString(endDate);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      registrationEndDate: formattedDate,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleInsurranceStartDate = () => {
    const newStartDate = formatDateToString(insurranceStartDate);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      insuranceStartDate: newStartDate,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleInsurranceEndDate = () => {
    const newEndDate = formatDateToString(insurranceEndDate);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      insuranceEndDate: newEndDate,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: keyof SelectedImages
  ) => {
    const file = event.target.files?.[0];

    if (!file) return;

    // Get file extension as a fallback for type validation
    const fileExtension = file.name.split(".").pop()?.toLowerCase();

    if (fileExtension !== "pdf") {
      errorToast("Please upload a valid PDF file.");
      return;
    }

    setSelectedImages((prevState) => ({
      ...prevState,
      [fieldName]: file,
    }));

    setFileNames((prevState: any) => ({
      ...prevState,
      [fieldName]: file.name,
    }));

    setIsFileChanged(true);
  };

  const reduxState = useSelector((state: any) => state);

  return (
    <>
      <div className={styles.registerATruckOrEdit5P}>
        <div className={styles.registerATruckOrEdit5PChild} />
        <main className={styles.frameA}>
          {currentStep != 8 && (
            <div className={styles.frameF}>
              <div className={styles.frameG}>
                <div className={styles.frameH}>
                  <div
                    className={styles.textButton}
                    onClick={onBackButtonClick}
                  >
                    <img
                      className={styles.icon}
                      loading="eager"
                      alt=""
                      src="/generics/icon.svg"
                    />
                    <div className={styles.inputFieldText}>Back</div>
                  </div>
                  <div className={styles.inputText}>5 of 6</div>
                </div>
                <div className={styles.frameGInner}>
                  <div className={styles.frameChild} />
                </div>
              </div>
            </div>
          )}
          <div className={styles.frameInputWithLabel}>
            <div className={styles.userNameParent}>
              <h2 className={styles.userName}>
                Registration expiration date&nbsp;
              </h2>
              <div className={styles.inputText1}>
                This documentation is needed in order to list your vehicle.
                Supported formats: PDF.
              </div>
            </div>
          </div>
          <section className={styles.nATheseframesseemtoberepeati}>
            <div className={styles.fileUploadFrame}>
              <div className={styles.textButton1}>
                <img
                  className={styles.icon1}
                  alt=""
                  src="/generics/icon-1.svg"
                />
                <div className={styles.text1}>Back&nbsp;</div>
              </div>
              <div className={styles.frameTextInputParent}>
                <div className={styles.frameTextInput}>
                  <div className={styles.inputText2}>Registration</div>
                  <div
                    className={`${styles.addImageButtonWrapper} hoverButtons`}
                  >
                    <label
                      htmlFor="fileInput"
                      className={styles.fileInputLabel}
                      style={{ width: "100%" }}
                      onClick={() => handleDivClick(fileInputRef1)}
                    >
                      <input
                        ref={fileInputRef1}
                        type="file"
                        accept="application/pdf"
                        id="fileInput-registrationFile"
                        style={{ display: "none" }}
                        onChange={(event) =>
                          handleFileChange(event, "registrationFile")
                        }
                      />
                      <div className={styles.addImageButton}>
                        <img
                          className={styles.iconoutlinedocument}
                          loading="eager"
                          alt=""
                          src="/generics/pdfdoc.svg"
                        />
                        <div className={styles.frameInputWithLabelInputT}>
                          {fileNames["registrationFile"] ? (
                            <div className={styles.fileName}>
                              {fileNames["registrationFile"]}
                            </div>
                          ) : (
                            <>
                              <div className={styles.addImage1}>
                                Add PDF file
                              </div>
                              <div className={styles.dragAndDrop}>
                                {idToUpdate?.files.vehicleRegistrationFile
                                  ? idToUpdate?.files.vehicleRegistrationFile
                                      .split("/")
                                      .pop()
                                  : `Click to select file`}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                {/* <div className={styles.frameLabelInputBasicInput}>
                  <div className={styles.inputWithLabel}>
                    <div className={styles.inputText3}>
                      Registration start date&nbsp;
                    </div>
                    <div className={styles.basicInput}>
                      <DatePicker
                        selected={startDate}
                        className={styles.inputText4}
                        dateFormat="MM/dd/yyyy"
                        placeholderText="MM/DD/YYYY"
                        onChange={(date: Date) => {
                          setStartDate(date);
                          setManualStartDateDateInput(
                            moment(date).format("MM/DD/yyyy").length > 10
                              ? ""
                              : moment(date).format("MM/DD/yyyy").length
                          );
                        }}
                        onChangeRaw={(e) => {
                          if (e.target.value == undefined) return;
                          let value = e.target.value.replace(/\D/g, "");
                          if (value.length >= 9) return;
                          if (value.length > 2) {
                            value = value.slice(0, 2) + "/" + value.slice(2);
                          }
                          if (value.length > 5) {
                            value = value.slice(0, 5) + "/" + value.slice(5);
                          }
                          setManualStartDateDateInput(value);
                        }}
                        onBlur={handleStartDateInputBlur}
                        value={manualStartDateDateInput}
                        showYearDropdown
                        scrollableYearDropdown
                        // placeholderText="01/20/2024"
                      />
                    </div>
                  </div>
                  <div className={styles.inputWithLabel1}>
                    <div className={styles.inputText5}>
                      Registration end date&nbsp;
                    </div>
                    <div className={styles.basicInput1}>
                      <DatePicker
                        selected={endDate}
                        placeholderText="MM/DD/YYYY"
                        className={styles.inputText4}
                        dateFormat="MM/dd/yyyy"
                        onChange={(date: Date) => {
                          setEndDate(date);
                          setManualEndDateDateInput(
                            moment(date).format("MM/DD/yyyy").length > 10
                              ? ""
                              : moment(date).format("MM/DD/yyyy").length
                          );
                        }}
                        onChangeRaw={(e) => {
                          if (e.target.value == undefined) return;
                          let value = e.target.value.replace(/\D/g, "");
                          if (value.length >= 9) return;
                          if (value.length > 2) {
                            value = value.slice(0, 2) + "/" + value.slice(2);
                          }
                          if (value.length > 5) {
                            value = value.slice(0, 5) + "/" + value.slice(5);
                          }
                          setManualEndDateDateInput(value);
                        }}
                        onBlur={handleEndDateInputBlur}
                        value={manualEndDateDateInput}
                        showYearDropdown
                        scrollableYearDropdown
                        // placeholderText="01/20/2024"
                      />
                    </div>
                  </div>
                </div> */}
              </div>
              <div className={styles.inputFieldInputWithLabel} />
              <div className={styles.inputFieldInputWithLabel1}>


                {/* Hidden FOR NOW */}


                
                {/* <div className={styles.nAThesenodesappeartobenested}>
                  <div className={styles.inputText7}>Insurance</div>
                  <div
                    className={`${styles.nAThesenodesappeartobenestedWrapper} hoverButtons`}
                  >
                    <label
                      htmlFor="fileInput"
                      className={styles.fileInputLabel}
                      style={{ width: "100%" }}
                      onClick={() => handleDivClick(fileInputRef2)}
                    >
                      <input
                        ref={fileInputRef2}
                        type="file"
                        accept="application/pdf"
                        id="fileInput-insurranceFile"
                        style={{ display: "none" }}
                        onChange={(event) =>
                          handleFileChange(event, "insurranceFile")
                        }
                      />
                      <div className={`${styles.addImageButton} hoverButtons`}>
                        <img
                          className={styles.iconoutlinedocument1}
                          loading="eager"
                          alt=""
                          src="/generics/pdfdoc.svg"
                        />
                        <div className={styles.nAThesenodesappeartobenested2}>
                          {fileNames["insurranceFile"] ? (
                            <div className={styles.fileName}>
                              {fileNames["insurranceFile"]}
                            </div>
                          ) : (
                            <>
                              <div className={styles.addImage1}>
                                Add PDF file
                              </div>
                              <div className={styles.dragAndDrop}>
                                {idToUpdate?.files.vehicleInsuranceFile
                                  ? idToUpdate?.files.vehicleInsuranceFile
                                      .split("/")
                                      .pop()
                                  : `Click to select file`}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </label>
                  </div>
                </div> */}
                {/* <div className={styles.inputFieldInputWithLabel2}>
                  <div className={styles.inputWithLabel2}>
                    <div className={styles.inputText8}>
                      Registration start date&nbsp;
                    </div>
                    <div className={styles.basicInput2}>
                      <DatePicker
                        selected={insurranceStartDate}
                        className={styles.inputText4}
                        dateFormat="MM/dd/yyyy"
                        placeholderText="MM/DD/YYYY"
                        onChange={(date: Date) => {
                          setInsurranceStartDate(date);
                          setManualInStartDateInput(
                            moment(date).format("MM/DD/yyyy").length > 10
                              ? ""
                              : moment(date).format("MM/DD/yyyy").length
                          );
                        }}
                        onChangeRaw={(e) => {
                          if (e.target.value == undefined) return;
                          let value = e.target.value.replace(/\D/g, "");
                          if (value.length >= 9) return;
                          if (value.length > 2) {
                            value = value.slice(0, 2) + "/" + value.slice(2);
                          }
                          if (value.length > 5) {
                            value = value.slice(0, 5) + "/" + value.slice(5);
                          }
                          setManualInStartDateInput(value);
                        }}
                        onBlur={handleInsurranceStartDateInputBlur}
                        value={manualInStartDateInput}
                        showYearDropdown
                        scrollableYearDropdown
                      />
                    </div>
                  </div>
                  <div className={styles.inputWithLabel3}>
                    <div className={styles.inputText10}>
                      Registration end date&nbsp;
                    </div>
                    <div className={styles.basicInput3}>
                      <DatePicker
                        selected={insurranceEndDate}
                        className={styles.inputText4}
                        dateFormat="MM/dd/yyyy"
                        placeholderText="MM/DD/YYYY"
                        onChange={(date: Date) => {
                          setInsurranceEndDate(date);
                          setManualInEndDateInput(
                            moment(date).format("MM/DD/yyyy").length > 10
                              ? ""
                              : moment(date).format("MM/DD/yyyy").length
                          );
                        }}
                        onChangeRaw={(e) => {
                          if (e.target.value == undefined) return;
                          let value = e.target.value.replace(/\D/g, "");
                          if (value.length >= 9) return;
                          if (value.length > 2) {
                            value = value.slice(0, 2) + "/" + value.slice(2);
                          }
                          if (value.length > 5) {
                            value = value.slice(0, 5) + "/" + value.slice(5);
                          }
                          setManualInEndDateInput(value);
                        }}
                        onBlur={handleInsurranceEndDateInputBlur}
                        value={manualInEndDateInput}
                        showYearDropdown
                        scrollableYearDropdown
                        // placeholderText="01/20/2024"
                      />
                    </div>
                  </div>
                </div> */}
                <div className={styles.inputText12}>
                  We will not use your insurance data in any way in the future,
                  this is required until another driver rents your truck.
                </div>
              </div>
              <div className={styles.inputFieldInputWithLabel3} />
              <div className={styles.inputFieldInputWithLabel4}>
                <div className={styles.inputTextParent}>
                  <div className={styles.inputText13}>
                    Technical inspection (Optional)
                  </div>
                  <div className={`${styles.frameWrapper} hoverButtons`}>
                    <label
                      htmlFor="fileInput"
                      className={styles.fileInputLabel}
                      style={{ width: "100%" }}
                      onClick={() => handleDivClick(fileInputRef3)}
                    >
                      <input
                        ref={fileInputRef3}
                        type="file"
                        accept="application/pdf"
                        id="fileInput-inspectionFile"
                        style={{ display: "none" }}
                        onChange={(event) =>
                          handleFileChange(event, "inspectionFile")
                        }
                      />
                      <div className={`${styles.iconoutlinedocumentParent}`}>
                        <img
                          className={styles.iconoutlinedocument2}
                          loading="eager"
                          alt=""
                          src="/generics/pdfdoc.svg"
                        />
                        <div className={styles.addImageParent}>
                          {fileNames["inspectionFile"] ? (
                            <div className={styles.fileName}>
                              {fileNames["inspectionFile"]}
                            </div>
                          ) : (
                            <>
                              <div className={styles.addImage1}>
                                Add PDF file
                              </div>
                              <div className={styles.dragAndDrop}>
                                {idToUpdate?.files.technicalInspectionFile
                                  ? idToUpdate?.files.technicalInspectionFile
                                      .split("/")
                                      .pop()
                                  : `Click to select file`}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                {/* <div className={styles.inputWithLabelParent}>
                  <div className={styles.inputWithLabel4}>
                    <div className={styles.inputText14}>
                      Registration start date&nbsp;
                    </div>
                    <div className={styles.basicInput4}>
                      <input
                        className={styles.inputText15}
                        placeholder="mm/dd/yyyy"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className={styles.inputWithLabel5}>
                    <div className={styles.inputText16}>
                      Registration end date&nbsp;
                    </div>
                    <div className={styles.basicInput5}>
                      <DatePicker
                        selected={inspectionDate}
                        className={styles.inputText4}
                        dateFormat="MM/dd/yyyy"
                        placeholderText="MM/DD/YYYY"
                        onChange={(date: Date) => {
                          setinspectionDate(date);
                          setManualInspectionDateInput(
                            moment(date).format("MM/DD/yyyy").length > 10
                              ? ""
                              : moment(date).format("MM/DD/yyyy").length
                          );
                        }}
                        onChangeRaw={(e) => {
                          if (e.target.value == undefined) return;
                          let value = e.target.value.replace(/\D/g, "");
                          if (value.length >= 9) return;
                          if (value.length > 2) {
                            value = value.slice(0, 2) + "/" + value.slice(2);
                          }
                          if (value.length > 5) {
                            value = value.slice(0, 5) + "/" + value.slice(5);
                          }
                          setManualInspectionDateInput(value);
                        }}
                        onBlur={handleInspectionDateInputBlur}
                        value={manualInspectionDateInput}
                        showYearDropdown
                        scrollableYearDropdown
                      />
                    </div>
                  </div>
                </div> */}
                <div className={styles.inputText18}>
                  We will not use your insurance data in any way in the future,
                  this is required until another driver rents your truck.
                </div>
              </div>
            </div>
          </section>
        </main>
        {currentStep != 8 && (
          <div className={styles.buttonFrame}>
            <div className={styles.buttonParent}>
              <button
                className={styles.button4}
                style={{ visibility: "hidden" }}
              >
                <div className={styles.textButtonText}>Save as draft</div>
              </button>
              <div className={styles.addImageButtonFrame}>
                <button className={styles.button5} onClick={onBackButtonClick}>
                  <div className={styles.inputWithLabel6}>Back</div>
                </button>
                <button className={styles.button6} onClick={handleNextClick}>
                  <div className={styles.text2}>Next</div>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default connectorAddTruckExpirationDate2(AddtruckExpirationDate2);
