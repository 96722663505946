import React, { useCallback, useState } from "react";
import { PhoneInput } from "react-international-phone";
import { Link } from "react-router-dom";
import styles from "./RegistrationOwnerShare.module.css";
import "react-international-phone/style.css";
import { RegistrationData, RootState } from "../store/types";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { updateRegistrationData } from "../store/actions/registrationActions";
import { errorToast } from "../components/Toast/ToastMessage";
import { parsePhoneNumber } from "libphonenumber-js";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

type VechicleOwnerProps = {
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  onNextButtonKeyDown: () => void;
  onChangeRouteNextButtonClick?: () => void;
  handleUpdateRegistrationData: (data: RegistrationData) => void;
  role: string;
  registerData?: RegistrationData;
  onClick?: () => void;
};

const mapDispatchToProps = {
  updateRegistrationData,
};

const mapStateToProps = (state: RootState) => ({
  registrationData: state.registrationData,
});

const connectorOwnerShare = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromReduxVechicleToRent = ConnectedProps<
  typeof connectorOwnerShare
>;

const RegistrationOwnerShare: React.FC<
  VechicleOwnerProps & PropsFromReduxVechicleToRent
> = ({
  onNextButtonClick,
  onBackButtonClick,
  onNextButtonKeyDown,
  updateRegistrationData,
  registrationData,
  ...props
}) => {
  const currentUrl = window.location.href;
  const [firstName, setFirstName] = useState<string>(
    registrationData.firstName
  );
  const [lastName, setLastName] = useState<string>(registrationData.lastName);
  const [phone, setPhone] = useState<string>(registrationData.phone);
  const [requiredField, setRequiredField] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const reduxState = useSelector((state: RootState) => state);
  const [password, setPassword] = useState<string>(
    reduxState.registrationData.password
  );

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;

    setPassword(newPassword);

    updateRegistrationData({
      ...registrationData,
      password: newPassword,
    });

    checkConditions(newPassword);
  };

  const handlePhoneNumber = (phone: string) => {
    const newPhoneNumber = phone;
    setPhone(newPhoneNumber);
    updateRegistrationData({
      ...registrationData,
      phone: newPhoneNumber,
    });
  };

  const onInputWithLabelClick = useCallback(() => {}, []);

  const handleNextClick = () => {
    if (!firstName || !lastName || !password || !phone) {
      const emptyField = !firstName
        ? "firstName"
        : !lastName
        ? "lastName"
        : !password
        ? "password"
        : "phone";
      setRequiredField(emptyField);

      return;
    } else {
      setRequiredField("");
    }

    try {
      const phoneNumber = parsePhoneNumber(phone, "US");
      if (phoneNumber.isValid()) {
        setIsValid(true);
      } else {
        setIsValid(false);
        return;
      }
      if (phoneNumber.country != "US") {
        errorToast("Phone number should only for US");
        return;
      }
    } catch (error) {
      setIsValid(false);
      return;
    }

    if (checkConditions(password).includes(false)) {
      errorToast("Please enter a strong password");
      return;
    }

    updateRegistrationData({
      ...registrationData,
      firstName: firstName,
      lastName: lastName,
      password: password,
      phone: phone,
      role: `${
        currentUrl.includes("registration_driver") ? "driver" : "renter"
      }`,
    });
    onNextButtonClick();
  };

  const handleBackButtonClick = () => {
    onBackButtonClick();
  };
  const checkConditions = (password: string) => {
    const conditions = [
      password.length >= 6,
      /[A-Z]/.test(password),
      /[a-z]/.test(password),
      /\d/.test(password),
      /[^a-zA-Z0-9]/.test(password),
    ];

    return conditions;
  };

  const passwordConditions = [
    "a minimum of 6 characters",
    "an uppercase character",
    "a lowercase character",
    "a number",
    "a special character",
  ];

  return (
    <div className={styles.vehicleOwner02}>
      <div className={styles.vehicleOwner02Child} />
      <div className={styles.backButtonParent}>
        <div
          className={`${styles.textButton} ${styles.backButton}`}
          onClick={handleBackButtonClick}
          style={{ cursor: "pointer" }}
        >
          <img className={styles.icon} alt="" src="/generics/icon5.svg" />
          <div className={styles.reefer}>Back</div>
        </div>
        <div
          className={styles.textButton}
          onClick={handleBackButtonClick}
          style={{ cursor: "pointer" }}
        >
          <div className={styles.stepcircleWithText}>
            <div className={styles.stepcircle}>
              <img className={styles.icon} alt="" src="/generics/check.svg" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Sign up</div>
            </div>
          </div>
          <div className={styles.instanceChild} />
          <div className={styles.stepcircleWithText1}>
            <div className={styles.stepcircle1}>
              <div className={styles.dot} />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Company details</div>
            </div>
          </div>
          <div className={styles.instanceChild} />
          <div className={styles.stepcircleWithText1}>
            <div className={styles.stepcircle1}>
              <div className={styles.dot} />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Verification</div>
            </div>
          </div>
        </div>
        <div className={styles.userNameParent}>
          <div className={styles.userName}>
            Sign up as an owner to share vehicle
          </div>
          <div className={styles.inputText3}></div>
        </div>
        <div className={styles.textButtonGroup}>
          <div className={styles.textButton1}>
            <img className={styles.icon} alt="" src="/generics/icon.svg" />
            <div className={styles.reefer}>Back&nbsp;</div>
          </div>
          <div className={styles.frameParent}>
            <div className={styles.inputWithLabelParent}>
              <div
                className={styles.inputWithLabel}
                onKeyDown={() => {}}
                role="button"
                onClick={onInputWithLabelClick}
              >
                <div className={styles.reefer}>First name&nbsp;</div>
                <div className={styles.basicInput}>
                  <input
                    type="text"
                    className={styles.inputField}
                    value={firstName}
                    placeholder="First name"
                    style={
                      requiredField === "firstName"
                        ? { border: "1px solid red", borderRadius: "8px" }
                        : { border: "none" }
                    }
                    maxLength={30}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div className={styles.inputWithLabel1}>
                <div className={styles.reefer}>Last name&nbsp;</div>
                <div className={styles.basicInput}>
                  <input
                    className={styles.inputField}
                    value={lastName}
                    type="text"
                    placeholder="Last name"
                    style={
                      requiredField === "lastName"
                        ? { border: "1px solid red", borderRadius: "8px" }
                        : { border: "none" }
                    }
                    maxLength={30}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className={styles.frameGroup}>
              <div className={styles.frameContainer}>
                <div className={styles.inputWithLabelWrapper}>
                  <div className={styles.inputWithLabel1}>
                    <div className={styles.reefer}>Password</div>
                    <div className={styles.basicInput}>
                      <input
                        className={styles.inputText9}
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        style={{
                          background: "transparent",
                          border:
                            requiredField === "password"
                              ? "1px solid red"
                              : "none",
                          borderRadius:
                            requiredField === "password" ? "8px" : "0px",
                        }}
                        onChange={handlePassword}
                      />
                      {showPassword ? (
                        <VisibilityOffIcon
                          onClick={toggleShowPassword}
                          className={`${styles.iconoutlineeye} hoverButtons`}
                        />
                      ) : (
                        <VisibilityIcon
                          onClick={toggleShowPassword}
                          className={`${styles.iconoutlineeye} hoverButtons`}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.inputWithLabelWrapper}>
                  <div className={styles.inputWithLabel1}>
                    <div className={styles.reefer}>Phone number</div>
                    <div className={styles.phoneNumberInput}>
                      <PhoneInput
                        style={
                          requiredField === "phone"
                            ? { border: "1px solid red", borderRadius: "8px" }
                            : {
                                border: "1px solid #94a3b8",
                                borderRadius: "5px",
                              }
                        }
                        className={styles.phoneNumberInput}
                        defaultCountry="US"
                        disableDialCodeAndPrefix={true}
                        value={phone}
                        onChange={(phone) => {
                          handlePhoneNumber(phone);
                        }}
                        inputStyle={{
                          width: "80%",
                          fontSize: "16px",
                          color: "#64748b",
                        }}
                      />
                    </div>
                    {!isValid && (
                      <p style={{ color: "red" }}>
                        Please add a valid US phone
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.body}>
                <div className={styles.title}>Your password must contain:</div>
                <div className={styles.frameDiv}>
                  {checkConditions(password).map((conditionMet, index) => (
                    <div
                      className={styles.iconsolidcheckCircleParent}
                      key={index}
                    >
                      <img
                        className={styles.icon}
                        alt=""
                        src={`/generics/iconsolidcheckcircle${
                          conditionMet ? "" : "1"
                        }.svg`}
                      />
                      <div className={styles.inputText12}>
                        {passwordConditions[index]}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameChild} />

          <div
            className={`errorBox ${requiredField ? "active" : ""}`}
            style={{
              display: requiredField ? "flex" : "none",
              height: requiredField ? "5vh" : "0",
            }}
          >
            Please, fill all required fields
          </div>
          <div
            className={`${styles.button1} hoverButtons`}
            onClick={handleNextClick}
          >
            <div className={styles.text} onKeyDown={() => {}} role="button">
              Next
            </div>
          </div>
          <div className={styles.inputTextContainer}>
            <div className={styles.inputText17}>Length</div>
            <div className={styles.tagParent}>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>Small</div>
              </div>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>Large</div>
              </div>
            </div>
          </div>
          <div className={styles.inputTextContainer}>
            <div className={styles.inputText17}>Lift Gate</div>
            <div className={styles.tagParent}>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>Rail Gate</div>
              </div>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>Tuck Under</div>
              </div>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>No Lift Gate</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.inputText19}>
          <span className={styles.inputTextTxtContainer}>
            <span>By confirming your email, you agree to our&nbsp;</span>
            <Link to="#">
              <span className={styles.termsOfService}>Terms of Service</span>
            </Link>
            <span>&nbsp;and that you have read and understood our&nbsp;</span>
            <Link to="#">
              <span className={styles.termsOfService}>Privacy Policy</span>
            </Link>
            <span>.</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default connectorOwnerShare(RegistrationOwnerShare);
