import React, { useEffect, useState } from "react";
import styles from "./HeroSection.module.css";
import styles2 from "./VehicleTypes.module.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import { FormHelperText } from "@mui/material";
import { Link } from "react-router-dom";
import Joyride from "react-joyride";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../components/Global/GlobalState";

const steps = [
  {
    target: `.${styles.inputGroup}`,
    content: "Here you can search for a vehicle by location and type",
  },
  {
    target: `.${styles2.cardContainer}`,
    content: "These are the vehicle types we specialize in.",
  },
];
const HeroSection: React.FC = () => {
  const { setSearchString, setSearchByLocation } = useGlobalContext();
  const [type, setType] = React.useState<string>("");
  const [open, setOpen] = React.useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(false);
  const [run, setRun] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      const beacon = document.querySelector(".react-joyride__beacon");
      if (beacon) {
        (beacon as HTMLElement).click();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem("hasVisited");
    if (!hasVisitedBefore) {
      setIsFirstVisit(true);
      localStorage.setItem("hasVisited", "true");
    }
  }, []);

  const handleChange = (event: SelectChangeEvent<typeof type>) => {
    let selectedType = event.target.value;
    setType(selectedType);
    setSearchString(selectedType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setRun(true);
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <section className={styles.container}>
      <Joyride
        steps={steps}
        run={isFirstVisit} // Run the tour if it's the first visit
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        locale={{
          next: "Start Tour",
          skip: "Skip",
          back: "Back",
          last: "Get Started",
        }}
        styles={{
          options: {
            zIndex: 999,
          },
        }}
      />
      <div className={styles.content}>
        <h1 className={styles.title}>
          Look for a truck or trailer available for rent
        </h1>
        <p className={styles.description}>
          Truck4 gives you access to thousands of commercial trucks, tractors,
          and trailers available for rent, lease and lease to purchase in your
          area.
        </p>
      </div>
      <div className={styles.searchSection}>
        <div className={styles.searchHeader}>
          <h2 className={styles.searchTitle}>
            Where do you want to find a vehicle today?
          </h2>
          <div className={styles.searchForm}>
            <div className={styles.inputGroup}>
              <label
                htmlFor="locationInput"
                className={styles["visually-hidden"]}
              >
                Location
              </label>
              <input
                id="locationInput"
                type="text"
                className={styles.locationInput}
                placeholder="Location, state, city"
                aria-label="Enter location, state, or city"
                onChange={(e) => setSearchByLocation(e.target.value)}
              />

              <FormControl
                sx={{
                  height: "53px",
                  width: "50%",
                  margin: 0,
                  borderLeft: "1px solid gray",
                }}
              >
                <Select
                  style={{
                    padding: 0,
                    margin: 0,
                    border: 0,
                    borderRadius: "0 8px 8px 0",
                    textAlign: "left",
                    color: "#64748b",
                  }}
                  className={styles.locationInput}
                  value={type}
                  onChange={handleChange}
                  displayEmpty
                  placeholder="Type"
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem style={{ display: "none" }} value="">
                    <em style={{ fontStyle: "normal" }}>Type</em>
                  </MenuItem>
                  <MenuItem value="truck">Truck</MenuItem>
                  <MenuItem value="trailer">Trailer</MenuItem>
                  <MenuItem value="boxtruck">Box Truck</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Link to="/main" className={styles.searchButton}>
              Search
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
