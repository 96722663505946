import { FunctionComponent, useState, useEffect, useRef } from "react";
import styles from "./css/AddTruckExpirationData.module.css";
import { updateTruckRegistrationData } from "../../store/actions/registrationActions";
import { TruckRegistrationData, RootStateTruckAdd } from "../../store/types";
import { connect } from "react-redux";
import { PropsFromReduxAddTruckToRent } from "./AddTruckApplyToRent";
import { errorToast, successToast } from "../../components/Toast/ToastMessage";
import { getAuthHeaders } from "../../utils/GetHeaders";
import { REACT_APP_UPLOAD_API_BASE_URL } from "../../utils/UrlConstants";
import heic2any from "heic2any";
import axios from "axios";
import { useDropzone } from "react-dropzone";

export type AddTruckExpirationDate = {
  handleUpdateTruckRegistrationData: (data: TruckRegistrationData) => void;
  truckRegData: TruckRegistrationData;
  onNextButtonClick: () => void;
  onBackButtonClick: (step?: number) => void;
  setFileNames: (value: any) => void;
  setDescription: (value: string) => void;
  description: string;
  fileNames: any;
  idToUpdate?: TruckRegistrationData;
  currentStep: number;
} & PropsFromReduxAddTruckToRent;

type SelectedImages = {
  threeQuarterViewImage: File | null;
  frontImage: File | null;
  passengerSideImage: File | null;
  backImage: File | null;
};

const mapDispatchToProps = {
  updateTruckRegistrationData,
};

const mapStateToProps = (state: RootStateTruckAdd) => ({
  truckAddRegistrationData: state.truckRegistrationData,
});

const connectorAddTruckExpirationDate = connect(
  mapStateToProps,
  mapDispatchToProps
);

const AddTruckExpirationDate: FunctionComponent<AddTruckExpirationDate> = ({
  onNextButtonClick,
  truckAddRegistrationData,
  updateTruckRegistrationData,
  onBackButtonClick,
  setFileNames,
  setDescription,
  description,
  fileNames,
  idToUpdate,
  currentStep,
}) => {
  const threeQuarterViewImage = useRef<HTMLInputElement | null>(null);
  const frontImage = useRef<HTMLInputElement | null>(null);
  const passengerSideImage = useRef<HTMLInputElement | null>(null);
  const backImage = useRef<HTMLInputElement | null>(null);
  const [isFileChanged, setIsFileChanged] = useState<boolean>(false);
  const [fileChanged, setFileChanged] = useState<string>("");
  const [requiredField, setrequiredField] = useState<string>("");

  const [selectedImages, setSelectedImages] = useState<SelectedImages>({
    threeQuarterViewImage: null,
    frontImage: null,
    passengerSideImage: null,
    backImage: null,
  });

  useEffect(() => {
    const singleImageUpload = async () => {
      const formData = new FormData();

      for (const [fieldName, file] of Object.entries(selectedImages)) {
        if (file) {
          try {
            let processedFile = file;
            if (file.type === "image/heic" || file.name.endsWith(".heic")) {
              const convertedBlob: any = await heic2any({
                blob: file,
                toType: "image/jpeg",
              });
              processedFile = new File(
                [convertedBlob],
                file.name.replace(/\.heic$/, ".jpg"),
                {
                  type: "image/jpeg",
                }
              );
            }
            formData.append(fieldName, processedFile);
          } catch (conversionError) {
            console.error(
              `Error converting ${file.name} from HEIC to JPEG:`,
              conversionError
            );
            errorToast("Error converting image format");
            return;
          }
        }
      }

      try {
        const url = `${REACT_APP_UPLOAD_API_BASE_URL}/upload/truck/images`;

        const response = await axios.post(url, formData, {
          headers: {
            ...getAuthHeaders(),
            "Content-Type": "multipart/form-data",
          },
        });

        const { data } = response;

        if (data.fileLinks) {
          const updatedRegistrationData: Partial<TruckRegistrationData> = {
            ...truckAddRegistrationData,
            images: {
              ...(truckAddRegistrationData?.images || {}),
              back: data.fileLinks.backImage,
              front: data.fileLinks.frontImage,
              passengerSide: data.fileLinks.passengerSideImage,
              threeQuarterView: data.fileLinks.threeQuarterViewImage,
            },
          };
          updateTruckRegistrationData(updatedRegistrationData);
          successToast("Image uploaded successfully");
        } else {
          setFileNames((prevState: any) => ({
            ...prevState,
            [fileChanged]: "",
          }));
          clearRefValue(fileChanged);
          errorToast(`Error: ${data.error}`);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        errorToast("Error uploading image");
      }
    };

    if (isFileChanged) singleImageUpload();
    setIsFileChanged(false);
  }, [selectedImages, isFileChanged, fileChanged]);

  useEffect(() => {
    fetchAndSetImages();
  }, []);

  const handleDivClick = (ref: React.RefObject<HTMLInputElement>) => {
    console.log("test");

    if (ref.current) {
      ref.current.click();
    }
  };

  const fetchAndSetImages = async () => {
    const threeQuarterViewFile = idToUpdate?.images.threeQuarterView
      ? await urlToFile(idToUpdate.images.threeQuarterView)
      : null;

    setSelectedImages({
      threeQuarterViewImage: threeQuarterViewFile,
      frontImage: null,
      passengerSideImage: null,
      backImage: null,
    });
  };

  const urlToFile = async (url: string) => {
    try {
      const response = await fetch(url);
      const mimeType = response.headers.get("content-type") || undefined;
      const filename = url.split("/").pop() || "image.png";
      const blob = await response.blob();
      const file = new File([blob], filename, { type: mimeType });
      console.log(file);
      return file;
    } catch (error) {
      console.error("Error converting URL to File:", error);
      return null;
    }
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(event.target.value);
    const inputValue = event.target.value;

    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      description: inputValue,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement> | any,
    fieldName: keyof SelectedImages
  ) => {
    const file = event.target.files?.[0];

    if (!file) return;

    console.log(file);

    setIsFileChanged(true);
    setSelectedImages((prevState) => ({
      ...prevState,
      [fieldName]: file,
    }));

    setFileNames((prevState: any) => ({
      ...prevState,
      [fieldName]: file.name,
    }));
    setFileChanged(fieldName);
  };

  const handleNextClick = async () => {
    console.log(fileNames["threeQuarterViewImage"]);
    if (fileNames["threeQuarterViewImage"] == undefined && !idToUpdate) {
      setrequiredField("threeQuarterImage");
      errorToast("Three quarter image is required");
      return;
    }
    onNextButtonClick();
  };

  const clearRefValue = (refName: string) => {
    switch (refName) {
      case "threeQuarterViewImage":
        if (threeQuarterViewImage.current)
          threeQuarterViewImage.current.value = "";
        break;
      case "frontImage":
        if (frontImage.current) frontImage.current.value = "";
        break;
      case "passengerSideImage":
        if (passengerSideImage.current) passengerSideImage.current.value = "";
        break;
      case "backImage":
        if (backImage.current) backImage.current.value = "";
        break;
      default:
        return;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      console.log(selectedImages);
      console.log(getRootProps);
      const files = acceptedFiles.map((file) => URL.createObjectURL(file));
      const newImages = {
        threeQuarterViewImage: files[0]
          ? new File([], files[0], { type: "image/jpeg" })
          : null,
        frontImage: files[1]
          ? new File([], files[1], { type: "image/jpeg" })
          : null,
        passengerSideImage: files[2]
          ? new File([], files[2], { type: "image/jpeg" })
          : null,
        backImage: files[3]
          ? new File([], files[3], { type: "image/jpeg" })
          : null,
      };
      setSelectedImages((prevImages) => ({
        ...prevImages,
        ...newImages,
      }));
      successToast("Image uploaded!");
    },
  });

  return (
    <>
      <div
        className={`${styles.registerATruckOrEdit4P} ${
          currentStep == 8 && "pb-4"
        }`}
      >
        <div className={styles.registerATruckOrEdit4PChild} />
        <main className={styles.parentFrame}>
          {currentStep != 8 && (
            <div className={styles.parentFrameInner}>
              <div className={styles.frameParent}>
                <div className={styles.textButtonParent}>
                  <div
                    className={styles.textButton}
                    onClick={() => {
                      if (
                        truckAddRegistrationData?.vehicleType === "trailer" ||
                        truckAddRegistrationData?.vehicleType === "boxtruck" ||
                        idToUpdate?.vehicleType === "trailer" ||
                        idToUpdate?.vehicleType === "boxtruck"
                      ) {
                        onBackButtonClick();
                      } else {
                        onBackButtonClick(2);
                      }
                    }}
                  >
                    <img
                      className={styles.icon}
                      loading="eager"
                      alt=""
                      src="/generics/icon.svg"
                    />
                    <div className={styles.textbuttonlabel}>Back</div>
                  </div>
                  <div className={styles.inputText}>4 of 6</div>
                </div>
                <div className={styles.rectangleWrapper}>
                  <div className={styles.frameChild} />
                </div>
              </div>
            </div>
          )}
          <div
            className={`${styles.parentFrameChild} ${
              currentStep == 8 && "mt-4"
            }`}
          >
            <div className={styles.userNameParent}>
              <h2 className={styles.userName}>Registration expiration date</h2>
              <div className={styles.inputText1}>
                The 3/4 view (frontImage & side) photo is required, including
                both the frontImage and side views, is mandatory to appeal to a
                larger number of potential renters.
              </div>
            </div>
          </div>
          <section className={styles.frameSection} style={{ width: "100%" }}>
            <div className={styles.textButtonGroup} style={{ width: "100%" }}>
              <div className={styles.inputTextParent}>
                <div
                  className={styles.inputText2}
                  style={{
                    color: requiredField == "threeQuarterImage" ? "red" : "",
                  }}
                >
                  3/4 view*
                </div>
                <div
                  {...getRootProps()}
                  className={`${styles.addImage}  hoverButtons`}
                  onClick={() => handleDivClick(threeQuarterViewImage)}
                >
                  <img
                    src={
                      idToUpdate && idToUpdate?.images?.threeQuarterView !== ""
                        ? idToUpdate?.images?.threeQuarterView
                        : "/add_truck/3_4pic.png"
                    }
                    // src={
                    //   idToUpdate
                    //     ? idToUpdate?.images?.threeQuarterView
                    //     : "/add_truck/3_4pic.png"
                    // }
                    loading="eager"
                    alt="3/4"
                    className={styles.docsAddImages}
                  />
                  <label
                    htmlFor="fileInput"
                    className={styles.fileInputLabel}
                    style={{ width: "100%" }}
                  >
                    <input
                      {...getInputProps()}
                      type="file"
                      accept="image/jpg, image/png, image/jpeg, image/heic"
                      id="fileInput-threeQuarterViewImage"
                      ref={threeQuarterViewImage}
                      style={{ display: "unset" }}
                      onChange={(event) =>
                        handleFileChange(event, "threeQuarterViewImage")
                      }
                    />

                    <div className={styles.iconoutlinedocumentParent}>
                      <img
                        className={styles.iconoutlinedocument}
                        loading="eager"
                        alt=""
                        src="/generics/iconoutlinedocument1.svg"
                      />
                      <div className={styles.addImageParent}>
                        {fileNames["threeQuarterViewImage"] ? (
                          <div className={styles.fileName}>
                            {fileNames["threeQuarterViewImage"]}
                          </div>
                        ) : (
                          <>
                            <div className={styles.addImage1}>Add image</div>
                            <div className={styles.dragAndDrop}>
                              {idToUpdate?.images.threeQuarterView
                                ? idToUpdate?.images.threeQuarterView
                                    .split("/")
                                    .pop()
                                : `Click to select file`}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div
                className={styles.inputTextGroup}
                onClick={() => handleDivClick(frontImage)}
              >
                <div className={styles.inputText2}>Front image of vehicle</div>
                <div
                  className={`${styles.addImage} hoverButtons`}
                  style={{ zIndex: "9999" }}
                  {...getRootProps()}
                >
                  <img
                    //
                    src={
                      idToUpdate && idToUpdate?.images?.front !== ""
                        ? idToUpdate?.images?.front
                        : "/add_truck/Front1.png"
                    }
                    loading="eager"
                    alt="3/4"
                    className={styles.docsAddImages}
                  />
                  <label
                    htmlFor="fileInput"
                    className={styles.fileInputLabel}
                    style={{ width: "100%" }}
                  >
                    <input
                      {...getInputProps()}
                      type="file"
                      accept="image/jpg, image/png, image/jpeg, image/heic"
                      id="fileInput-frontImage"
                      ref={frontImage}
                      style={{ display: "unset" }}
                      onChange={(event) =>
                        handleFileChange(event, "frontImage")
                      }
                    />
                    <div className={styles.iconoutlinedocumentGroup}>
                      <img
                        className={styles.iconoutlinedocument1}
                        loading="eager"
                        alt=""
                        src="/generics/iconoutlinedocument1.svg"
                      />
                      <div className={styles.addImageGroup}>
                        {fileNames["frontImage"] ? (
                          <div className={styles.fileName}>
                            {fileNames["frontImage"]}
                          </div>
                        ) : (
                          <>
                            <div className={styles.addImage1}>Add image</div>
                            <div className={styles.dragAndDrop}>
                              {idToUpdate?.images.front
                                ? idToUpdate?.images.front.split("/").pop()
                                : `Click to select file`}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className={styles.inputTextContainer}>
                <div className={styles.inputText2}>
                  Passenger side of vehicle
                </div>
                <div
                  className={`${styles.addImage4}  hoverButtons`}
                  onClick={() => handleDivClick(passengerSideImage)}
                >
                  <img
                    src={
                      idToUpdate && idToUpdate?.images?.passengerSide !== ""
                        ? idToUpdate?.images?.passengerSide
                        : "/add_truck/Passenger_Side.png"
                    }
                    loading="eager"
                    alt="Passenger Side"
                    className={styles.docsAddImages}
                  />
                  <label
                    htmlFor="fileInput"
                    className={styles.fileInputLabel}
                    style={{ width: "100%" }}
                  >
                    <input
                      type="file"
                      accept="image/jpg, image/png, image/jpeg, image/heic"
                      id="fileInput-passengerSideImage"
                      ref={passengerSideImage}
                      style={{ display: "unset" }}
                      onChange={(event) =>
                        handleFileChange(event, "passengerSideImage")
                      }
                    />
                    <div className={styles.iconoutlinedocumentContainer}>
                      <img
                        className={styles.iconoutlinedocument2}
                        loading="eager"
                        alt=""
                        src="/generics/iconoutlinedocument1.svg"
                      />
                      <div className={styles.addImageContainer}>
                        {fileNames["passengerSideImage"] ? (
                          <div className={styles.fileName}>
                            {fileNames["passengerSideImage"]}
                          </div>
                        ) : (
                          <>
                            <div className={styles.addImage1}>Add image</div>
                            <div className={styles.dragAndDrop}>
                              {idToUpdate?.images.passengerSide
                                ? idToUpdate?.images.passengerSide
                                    .split("/")
                                    .pop()
                                : `Click to select file`}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.inputText2}>Back Image</div>
                <div
                  className={`${styles.addImage6} hoverButtons`}
                  onClick={() => handleDivClick(backImage)}
                >
                  <img
                    src={
                      idToUpdate && idToUpdate?.images?.back !== ""
                        ? idToUpdate?.images?.back
                        : "/add_truck/back.png"
                    }
                    loading="eager"
                    alt="Back"
                    className={styles.docsAddImages}
                  />
                  <label
                    htmlFor="fileInput"
                    className={styles.fileInputLabel}
                    style={{ width: "100%" }}
                  >
                    <input
                      type="file"
                      accept="image/jpg, image/png, image/jpeg, image/heic"
                      id="fileInput-backImage"
                      ref={backImage}
                      style={{ display: "unset" }}
                      onChange={(event) => handleFileChange(event, "backImage")}
                    />
                    <div className={styles.iconoutlinedocumentParent1}>
                      <img
                        className={styles.iconoutlinedocument3}
                        loading="eager"
                        alt=""
                        src="/generics/iconoutlinedocument1.svg"
                      />
                      <div className={styles.addImageParent1}>
                        {fileNames["backImage"] ? (
                          <div className={styles.fileName}>
                            {fileNames["backImage"]}
                          </div>
                        ) : (
                          <>
                            <div className={styles.addImage1}>Add image</div>
                            <div className={styles.dragAndDrop}>
                              {idToUpdate?.images.back
                                ? idToUpdate?.images.back.split("/").pop()
                                : `Click to select file`}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div
              className={styles.inputWithLabel}
              style={{ width: "60%", margin: "auto", marginBottom: "2vh" }}
            >
              <div className={styles.inputText6}>Description*</div>
              <div className={styles.textareaInput}>
                <textarea
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "0",
                    resize: "none",
                  }}
                  className={styles.inputText7}
                  placeholder="Placeholder"
                  value={description}
                  onChange={handleDescriptionChange}
                />
              </div>
            </div>
          </section>
        </main>
        {currentStep != 8 && (
          <div className={styles.inputFieldPair}>
            <div className={styles.buttonContainer}>
              <button
                className={styles.button4}
                style={{ visibility: "hidden" }}
              >
                <div className={styles.textContent}>Save as draft</div>
              </button>
              <div className={styles.twoButtonsFrame}>
                <button className={styles.button5}>
                  <div
                    className={styles.inputFieldsFrame}
                    onClick={() => {
                      if (
                        truckAddRegistrationData?.vehicleType === "trailer" ||
                        truckAddRegistrationData?.vehicleType === "boxtruck" ||
                        idToUpdate?.vehicleType === "trailer" ||
                        idToUpdate?.vehicleType === "boxtruck"
                      ) {
                        onBackButtonClick();
                      } else {
                        onBackButtonClick(2);
                      }
                    }}
                  >
                    Back
                  </div>
                </button>
                <button className={styles.button6} onClick={handleNextClick}>
                  <div className={styles.text3}>Next</div>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default connectorAddTruckExpirationDate(AddTruckExpirationDate);
