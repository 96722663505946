import React, { useEffect, useState } from "react";
import styles from "./ProductPage.module.css";
import TextField from "@mui/material/TextField";
import Form from "react-bootstrap/Form";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { InputAdornment, ThemeProvider } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { errorToast, successToast } from "../../components/Toast/ToastMessage";
import { Tab, Tabs, Box } from "@mui/material";
import { theme } from "../Map_Page/MapPageSelector";
import { getAuthHeaders } from "../../utils/GetHeaders";
import { REACT_APP_TRUCK_API_BASE_URL } from "../../utils/UrlConstants";
import axios from "axios";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface InfoContainerProps {
  truck: any;
  mail: string;
  ownerNumber: string;
}

const InfoContainer: React.FC<InfoContainerProps> = ({
  truck,
  mail,
  ownerNumber,
}) => {
  const [showPhoneNumber, setShowPhoneNumber] = useState<boolean>(false);
  const [openFrom, setOpenFrom] = useState<boolean>(false);
  const [openTo, setOpenTo] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [truckId, setTruckId] = useState<string>("");
  const [bookingFlag, setBookingFlag] = useState<boolean>(false);
  const today = new Date();
  const [showEmail, setShowEmail] = useState<boolean>(false);
  const [value, setValue] = useState<Dayjs | null>(dayjs("2022-04-17T15:30"));
  const [agreementType, setAgreementType] = useState<number>(0);
  const [copyStatus, setCopyStatus] = useState("");

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(mail)
      .then(() => {
        setCopyStatus("Copied!");
        setTimeout(() => setCopyStatus(""), 2000); // Clear message after 2 seconds
        successToast("Email copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  useEffect(() => {}, [agreementType]);

  const handleAgreementType = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    console.log(newValue);
    setAgreementType(newValue);
  };

  const handleClickFrom = () => {
    setOpenFrom(true);
  };

  useEffect(() => {
    setTruckId(truck._id);

    console.log(truck);
  }, [truck]);

  useEffect(() => {
    if (fromDate != null) setBookingFlag(true);
    else setBookingFlag(false);
  }, [fromDate, toDate]);

  const handleCloseFrom = () => {
    setOpenFrom(false);
  };

  const handleFromDateChange = (date: any) => {
    setFromDate(date);
    handleCloseFrom();
  };

  const handleBooking = async () => {
    if (!fromDate) {
      errorToast("Please select 'From' and 'To' dates");
      return;
    }

    if (toDate && fromDate > toDate) {
      errorToast("From date cannot be greater than To date");
      return;
    }

    try {
      const requestData: any = {
        agreementType: getAgreementType(agreementType),
        startDate: fromDate,
        terms: "Terms?",
      };

      if (toDate) {
        requestData.endDate = toDate;
      }

      const response = await axios.post(
        `${REACT_APP_TRUCK_API_BASE_URL}/trucks/${truckId}/bookings/register`,
        requestData,
        {
          headers: {
            ...getAuthHeaders(),
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.data.success) {
        errorToast(`Failed to book: ${response.data.error}`);
      } else {
        successToast(`Booked successfully!`);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.error || error.message || "An error occurred";
      errorToast(`Error: ${errorMessage}`);
    }
  };

  const getAgreementType = (type: number) => {
    switch (type) {
      case 0:
        return "rent";
      case 1:
        return "lease";
      case 2:
        return "leasetopurchase";
      default:
        return "Unknown";
    }
  };

  const handleClickTo = () => {
    setOpenTo(true);
  };

  const handleCloseTo = () => {
    setOpenTo(false);
  };

  const handleToDateChange = (date: any) => {
    setToDate(date);
    handleCloseTo();
  };

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const triggerScrollY = 50;
      if (window.scrollY > triggerScrollY) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`${styles.frameParent5} ${isScrolled ? styles.scrolled : ""}`}
    >
      {truck.status != "in rent" ? (
        <div className={styles.tabExampleLightDesktopParent}>
          <div className={styles.tabExampleLightDesktop}>
            <ThemeProvider theme={theme}>
              <Box
                sx={{
                  flexGrow: 1,

                  bgcolor: "background.paper",
                  width: "inherit",
                }}
              >
                <Tabs
                  style={{ minHeight: "unset" }}
                  value={agreementType}
                  onChange={handleAgreementType}
                  aria-label="scrollable auto tabs example"
                  indicatorColor="secondary"
                  textColor="primary"
                >
                  {truck.enableForRent ? (
                    <Tab
                      className={styles.infoContainerTabs}
                      value={0}
                      label="Rent"
                    />
                  ) : (
                    <></>
                  )}

                  {truck.enableForLease ? (
                    <Tab
                      className={styles.infoContainerTabs}
                      value={1}
                      label="Lease"
                    />
                  ) : (
                    <></>
                  )}
                  {truck.enableForLeaseForPurchase ? (
                    <Tab
                      className={styles.infoContainerTabs}
                      value={2}
                      label="Lease to purchase"
                    />
                  ) : (
                    <></>
                  )}
                </Tabs>
              </Box>
            </ThemeProvider>
            {agreementType === 2 ? <></> : <div className={styles.underLine} />}
          </div>
          <div style={{ position: "relative" }}>
            <div className={styles.usd81dailyParent}>
              <div className={styles.usd81daily}>
                USD $
                {agreementType === 2
                  ? truck.LeaseForPurchase.pricePerWeek
                  : agreementType === 1
                  ? truck.leasePerDay
                  : truck.rentPerDay}
                {agreementType === 0
                  ? " / " + truck.rentPriceType
                  : agreementType === 1
                  ? " / " + truck.leasePriceType
                  : agreementType === 2
                  ? " / weekly"
                  : " "}
              </div>

              <div
                className={styles.usd025ratePer}
                style={
                  (agreementType === 0 &&
                    (truck.rentPrepaidMileage === 0 ||
                      truck.rentPrepaidMileage == null)) ||
                  (agreementType === 1 &&
                    (truck.leasePrepaidMileage === 0 ||
                      truck.rentPrepaidMileage == null))
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                {agreementType != 2 ? "USD $" : ""}

                {agreementType === 2
                  ? truck.LeaseForPurchase.numberOfWeeks
                  : agreementType === 1
                  ? truck.leasePrepaidMileage
                  : truck.rentPrepaidMileage}
                {agreementType === 2
                  ? " weeks"
                  : agreementType === 1
                  ? "/rate per mile"
                  : "/rate per mile"}
              </div>
            </div>

            <div className={styles.frameParent6}>
              <div
                className={styles.inputWithLabelContainer}
                style={{ width: "100%" }}
              >
                {agreementType === 2 ? (
                  <></>
                ) : (
                  <>
                    <div className={styles.inputWithLabel4}>
                      <TextField
                        id="input-with-icon-textfield"
                        label="From"
                        value={fromDate ? format(fromDate, "yyyy/MM/dd") : ""}
                        onClick={handleClickFrom}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CalendarMonthIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end"></InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                              borderColor: "#aaaaaa", // Hover border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#fa6f32", // Focused border color
                            },
                          },
                        }}
                      />

                      <DatePicker
                        minDate={today}
                        popperClassName={styles.datePickerCalendar}
                        open={openFrom}
                        selected={fromDate}
                        onChange={handleFromDateChange}
                        onClickOutside={handleCloseFrom}
                        className={styles.datePicker}
                        dateFormat="yyyy/MM/dd"
                      />
                    </div>
                    <div className={styles.inputWithLabel5}>
                      <TextField
                        id="input-with-icon-textfield"
                        label="To (optional)"
                        value={toDate ? format(toDate, "yyyy/MM/dd") : ""}
                        onClick={handleClickTo}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CalendarMonthIcon />
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                              borderColor: "#aaaaaa", // Hover border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#fa6f32", // Focused border color
                            },
                          },
                        }}
                      />
                      <DatePicker
                        minDate={today}
                        popperClassName={styles.datePickerCalendar}
                        open={openTo}
                        selected={toDate}
                        onChange={handleToDateChange}
                        onClickOutside={handleCloseTo}
                        className={styles.datePicker}
                        dateFormat="yyyy/MM/dd"
                      />
                    </div>
                  </>
                )}
              </div>

              {agreementType === 2 ? (
                <></>
              ) : (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["TimePicker", "TimePicker"]}
                    sx={{
                      width: "100%",
                      overflow: "none",
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          border: "1px solid #aaaaaa", // Hover border color
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#fa6f32 !important", // Focused border color
                        },
                      },
                    }}
                  >
                    <TimePicker
                      sx={{ width: "100%" }}
                      label="Pick up time"
                      value={value}
                      onChange={(newValue) => setValue(newValue)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              )}

              {agreementType === 2 ? (
                <></>
              ) : (
                <div className={styles.checkbox23}>
                  <Form.Check
                    aria-label="option 1"
                    className={styles.iAcceptCheckbox}
                  />
                  <div className={styles.textDescription12}>
                    I accept the Terms & Conditions
                  </div>
                </div>
              )}
            </div>
          </div>
          {agreementType === 2 ? <></> : <div className={styles.frameChild3} />}
          {agreementType === 2 && truck.LeaseForPurchase?.description ? (
            <div className={styles.textDescription12}>
              {truck.LeaseForPurchase.description}
            </div>
          ) : null}
          <div className={styles.buttonParent}>
            {agreementType === 2 ? (
              <div
                className={styles.button4}
                onClick={() => setShowEmail(true)}
              >
                {copyStatus ? (
                  <span className={styles.copyStatus}>{copyStatus}</span>
                ) : !showEmail ? (
                  <div className={styles.text18}>Send message to owner</div>
                ) : (
                  <div className="d-flex" onClick={copyToClipboard}>
                    <div className={styles.text18}>{mail}</div>
                  </div>
                )}
              </div>
            ) : (
              <div
                className={
                  agreementType === 2
                    ? styles.button2
                    : bookingFlag && truck.status !== "inrent"
                    ? styles.button4
                    : styles.button2
                }
                onClick={truck.status !== "inrent" ? handleBooking : undefined}
              >
                <div
                  className={`${styles.text16} ${
                    truck.status === "inrent" ? styles.inRent : ""
                  }`}
                >
                  {truck.status === "inrent" ? "In Rent" : "Book"}
                </div>
              </div>
            )}

            <div
              className={styles.button3}
              onClick={() => {
                setShowPhoneNumber(!showPhoneNumber);
              }}
            >
              {showPhoneNumber ? (
                <div className={styles.phoneNumberDiv}>
                  <b>{ownerNumber}</b>
                </div>
              ) : (
                <>
                  <img
                    className={styles.iconsolidphone}
                    alt=""
                    src="/truck/iconsolidphone.svg"
                  />
                  <div className={styles.text17}>Show phone number</div>
                </>
              )}
            </div>
            {agreementType === 2 ? (
              <></>
            ) : (
              <div
                className={styles.button4}
                onClick={() => setShowEmail(true)}
              >
                {copyStatus ? (
                  <span className={styles.copyStatus}>{copyStatus}</span>
                ) : !showEmail ? (
                  <div className={styles.text18}>Send message to owner</div>
                ) : (
                  <div className="d-flex" onClick={copyToClipboard}>
                    <div className={styles.text18}>{mail}</div>
                  </div>
                )}
              </div>
            )}
          </div>
          <Accordion
            style={{
              width: "100%",
              borderRadius: "8px",
              background: "#F8FAFC",
              border: "1px solid #9393932b",
            }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              className={styles.accordionParent}
            >
              <Typography style={{ fontWeight: 600 }}> Rates</Typography>
              <Typography style={{ paddingRight: "15%", fontWeight: 600 }}>
                ${truck.rentPerDay}.00
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <hr />
              <Typography>
                <div className={styles.accordionChild}>
                  <span>Taxes:</span>
                  <span> ${truck.taxes}</span>
                </div>
              </Typography>
              <hr />
              <Typography>
                <div className={styles.accordionChild}>
                  <span>Prepaid Mileage:</span>
                  <span> ${truck.rentPrepaidMileage}</span>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <>
          <div>
            <div className={styles.tabItem} style={{ color: "#fa6f32" }}>
              Truck is already booked
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InfoContainer;
