import { FunctionComponent, useState, useEffect } from "react";
import styles from "./css/OrganizationPage.module.css";
import styles2 from "../Map_Page/MapPage.module.css";
import Footer from "../Add_Truck/Footer";
import TrucksList from "../Map_Page/TrucksList";
import Loader from "../../components/Loader/Loader";
import OrganizationContact from "./OrganizationContact";
import axios from "axios";
import { errorToast } from "../../components/Toast/ToastMessage";
import { useParams } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import {
  REACT_APP_PAGINATION_OFFSET,
  REACT_APP_ORG_API_BASE_URL,
} from "../../utils/UrlConstants";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? "gray" : "gray",
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const CompanyPage: FunctionComponent = () => {
  const [selectedHearts, setSelectedHearts] = useState<number[]>([]);

  const handleHeartClick = (index: any) => {
    if (selectedHearts.includes(index)) {
      setSelectedHearts(selectedHearts.filter((item) => item !== index));
    } else {
      setSelectedHearts([...selectedHearts, index]);
    }
  };
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [showPhoneNumber, setShowPhoneNumber] = useState<boolean>(false);
  const [company, setCompany] = useState<any>([]);
  const [fullDataCount, setfullDataCount] = useState<number>(0);
  const [organizationTrucks, setOrganizationTrucks] = useState([]);
  //Mobile Drawer
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    fetchOrganization(id);
    fetchOrganizationTrucks(id);
  }, []);

  const togglePhoneNumber = () => {
    setShowPhoneNumber(!showPhoneNumber);
  };

  const offset: number = parseInt(REACT_APP_PAGINATION_OFFSET || "8");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < Math.ceil(fullDataCount / offset)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const fetchOrganizationTrucks = async (id: string | null = null) => {
    setLoading(true);
    var url = `${REACT_APP_ORG_API_BASE_URL}/organization/${id}/trucks`;
    try {
      const response = await axios.get(url);

      setOrganizationTrucks(response.data.trucks);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOrganizationTrucks([]);
      setfullDataCount(0);
      errorToast("Error fetching trucks");
    }
  };

  const fetchOrganization = async (id: string | null = null) => {
    setLoading(true);
    var url = `${REACT_APP_ORG_API_BASE_URL}/organization/${id}`;
    try {
      const response = await axios.get(url);
      setCompany(response.data.organization);
      console.log(response.data.organization);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setCompany([]);
      setfullDataCount(0);
      errorToast("Error fetching organization");
      console.error("Error fetching organization:", error);
    }
  };
  const startIdx = (currentPage - 1) * offset;

  return (
    <>
      <div className={styles.companyPage}>
        <div className={styles.companyPageInner}>
          <div className={styles.frameParent}>
            <div className={styles.frameGroup}>
              <div className={styles.frameContainer}>
                {loading ? (
                  <Loader />
                ) : (
                  <div
                    className={`${styles2.vecicleCardGroup} ${styles2.mainTruckList}`}
                    style={{ height: "100vh !important" }}
                  >
                    <TrucksList
                      trucks={organizationTrucks}
                      organizationInfo={company}
                      startIdx={startIdx}
                      styles={styles2}
                      handleHeartClick={handleHeartClick}
                      selectedHearts={selectedHearts}
                    />
                  </div>
                )}

                <div className={styles.resultsFound}>20 results found</div>
                <div className={styles.centeredPageNumbersdesktop}>
                  <div className={styles.topBorder} />
                  {fullDataCount / offset != 0 && (
                    <div className={styles.links}>
                      <div className={styles.moreDistantButNoLessImpoParent}>
                        <div className={styles.topBorder1} />
                        <div className={styles.content}>
                          <img
                            className={styles.iconoutlinesearch}
                            alt=""
                            src="/generics/arrow-narrow-left1.svg"
                          />
                          <div
                            className={`${styles.previous} hoverButtons`}
                            onClick={handlePreviousClick}
                          >
                            Previous
                          </div>
                        </div>
                      </div>
                      <div className={styles.numberLinks}>
                        {[...Array(Math.ceil(fullDataCount / offset))].map(
                          (_, index) => (
                            <div
                              key={index}
                              className={`${
                                styles.moreDistantButNoLessImpoParent
                              } ${
                                currentPage === index + 1
                                  ? styles.activePage
                                  : ""
                              }`}
                              onClick={() => handlePageClick(index + 1)}
                            >
                              <div
                                className={
                                  styles[
                                    `topBorder${
                                      currentPage === index + 1 ? 3 : 1
                                    }`
                                  ]
                                }
                              />
                              <div className={styles.content1}>
                                <div
                                  className={`${styles.previous} hoverButtons`}
                                  style={{
                                    color:
                                      currentPage === index + 1
                                        ? "#fb814c"
                                        : "inherit",
                                  }}
                                >
                                  {index + 1}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                      <div className={styles.moreDistantButNoLessImpoParent}>
                        <div className={styles.topBorder1} />
                        <div className={`${styles.content8} hoverButtons`}>
                          <div
                            className={`${styles.previous} hoverButtons`}
                            onClick={handleNextClick}
                          >
                            Next
                          </div>
                          <img
                            className={styles.iconoutlinesearch}
                            alt=""
                            src="/generics/arrow-narrow-right1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.frameParent8}>
                <div className={styles.reviewsParent}>
                  <div className={styles.reviews}>Reviews</div>
                  <div className={styles.frameWrapper8}>
                    {/* <div className={styles.frameParent9}>
                      <div className={styles.iconsolidstarWrapper}>
                        <img
                          className={styles.iconsolidstar}
                          alt=""
                          src="/organization/iconsolidstar.svg"
                        />
                      </div>
                      <div className={styles.div8}>5.0</div>
                      <div className={styles.div9}>(12)</div>
                    </div> */}
                  </div>
                </div>
                <div className={styles.textParent}>
                  <div className={styles.text}>The truck</div>
                  <div className={styles.frameParent10}>
                    <div className={styles.frameWrapper9}>
                      <div className={styles.frameParent11}>
                        <div className={styles.iconsolidstarParent}>
                          <img
                            className={styles.iconsolidstar1}
                            alt=""
                            src="/organization/iconsolidstar.svg"
                          />
                          <img
                            className={styles.iconsolidstar2}
                            alt=""
                            src="/organization/iconsolidstar.svg"
                          />
                          <img
                            className={styles.iconsolidstar3}
                            alt=""
                            src="/organization/iconsolidstar.svg"
                          />
                          <img
                            className={styles.iconsolidstar4}
                            alt=""
                            src="/organization/iconsolidstar.svg"
                          />
                          <img
                            className={styles.iconoutlinestar}
                            alt=""
                            src="/organization/iconoutlinestar.svg"
                          />
                        </div>
                        <div className={styles.div10}>5.0</div>
                      </div>
                    </div>
                    <div className={styles.vecicleCardrectangle12728}>
                      <div className={styles.vecicleCardrectangle1272Child6} />
                    </div>
                    <div className={styles.div11}>8/21/2023</div>
                    <div className={styles.vecicleCardrectangle127116}>
                      <div className={styles.vecicleCardrectangle1271Child13} />
                    </div>
                    <div className={styles.johnSmith}>John Smith</div>
                  </div>
                  <div className={styles.text1}>
                    The truck I drove was absolutely fantastic! It handled like
                    a dream and had plenty of power to get me where I needed to
                    go. I would definitely recommend it to anyone in the market
                    for a reliable and efficient truck.
                  </div>
                </div>
                <div className={styles.topBorder10} />
              </div>
            </div>
            <div className={styles.frameParent16}>
              <div className={styles.drawer}>
                {isMobile ? (
                  <>
                    <Root>
                      <CssBaseline />
                      <Global
                        styles={{
                          ".MuiDrawer-root > .MuiPaper-root": {
                            height: `calc(93% - ${drawerBleeding}px)`,
                            overflow: "visible",
                          },
                        }}
                      />
                      <SwipeableDrawer
                        anchor="bottom"
                        open={open}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                        swipeAreaWidth={drawerBleeding}
                        disableSwipeToOpen={false}
                        ModalProps={{
                          keepMounted: true,
                        }}
                      >
                        <StyledBox
                          sx={{
                            position: "absolute",
                            top: -drawerBleeding,
                            paddingTop: "8px",
                            boxShadow: "0px 0px 18px 0px rgba(0,0,0,0.75)",
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            visibility: "visible",
                            right: 0,
                            left: 0,
                            padding: "10%",
                          }}
                        >
                          <Puller />
                          <Typography
                            sx={{
                              p: 2,
                              textAlign: "center",
                              color: "text.secondary",
                            }}
                          >
                            Contact Details
                          </Typography>
                          <OrganizationContact
                            togglePhoneNumber={togglePhoneNumber}
                            showImage={false}
                            company={company}
                            showPhoneNumber={showPhoneNumber}
                          />
                          <Skeleton variant="rectangular" height="100%" />
                        </StyledBox>
                      </SwipeableDrawer>
                    </Root>
                  </>
                ) : (
                  <>
                    <OrganizationContact
                      togglePhoneNumber={togglePhoneNumber}
                      showImage={true}
                      company={company}
                      showPhoneNumber={showPhoneNumber}
                    />
                  </>
                )}
              </div>
              <div className={styles.component2}>
                <img
                  className={styles.informationCircleIcon}
                  alt=""
                  src="/organization/information-circle.svg"
                />
                <div className={styles.content9}>
                  <div className={styles.title}>
                    Anything else you’d like to know? Message us
                  </div>
                  <div className={styles.alertstextLink}>
                    <div className={styles.text7}>Details →</div>
                  </div>
                </div>
              </div>
              <div className={`${styles.iconoutlineflagParent} disabledButton`}>
                <img
                  className={styles.iconoutlineflag}
                  alt=""
                  src="/organization/iconoutlineflag.svg"
                />
                <div className={styles.reportAnCompany}>Report a company</div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CompanyPage;
